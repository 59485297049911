*,
::after,
::before {
    box-sizing: border-box
}

a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
tt,
ul,
var {
    border: 0;
    margin: 0;
    outline: none !important;
    padding: 0;
    vertical-align: baseline;
    font-family: "DM Sans", sans-serif
}

@font-face {
    font-family: "GreatSejagad";
    /* src: url("../font/GreatSejagad.eot"); */
    /* src: url("../font/GreatSejagad.eot") format("embedded-opentype"), url("../font/GreatSejagad.woff2") format("woff2"), url("../font/GreatSejagad.woff") format("woff"), url("../font/GreatSejagad.ttf") format("truetype"), url("../font/GreatSejagad.svg#GreatSejagad") format("svg") */
}

body {
    margin: 0;
    padding: 0;
    background: #fff;
    background-repeat: repeat-x;
    font-size: 14px;
    color: #272320
}

p {
    font-family: "DM Sans", sans-serif
}

a {
    color: inherit;
    outline: none !important;
    text-decoration: none
}

a:hover {
    color: inherit;
    text-decoration: none
}

.dropdown-toggle::after {
    border: none !important;
    content: "";
    font-family: "fontAwesome";
    top: 8px;
    right: -8px;
    position: absolute
}

button:focus {
    outline: none !important
}

ol {
    padding-left: 0
}

ol li {
    list-style: inherit
}

ul {
    padding: 0;
    list-style: none;
    margin: 0
}

ul li {
    list-style: none
}

img {
    vertical-align: top;
    max-width: 100%;
    border: none;
    outline: none
}

.btn {
    border: none !important
}

.btn:focus {
    box-shadow: none;
    border: none;
    outline: 0
}

.btn:focus,
.form-control:focus,
.resizeselect:focus {
    box-shadow: none;
    border: none;
    outline: 0
}

header {
    position: relative;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    z-index: 2
}

.menu-mobile {
    background: #ffc107
}

.cart-btn-links {
    position: relative
}

.cart-btn-links span:first-child {
    width: 25px;
    height: 25px;
    background: #535cfb;
    color: #fff !important;
    overflow: hidden;
    border-radius: 50%;
    display: grid;
    align-content: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.cart-btn-links .ion-0 {
    width: 26px;
    display: inline-block
}

.login-btn-links .oipn {
    width: 24px;
    display: inline-block
}

.navbar-light .navbar-nav .nav-link {
    font-family: "DM Sans", sans-serif;
    font-weight: 600;
    font-size: 17px;
    color: #0a0a0a;
    padding-left: 10px;
    padding-right: 10px;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    text-wrap: nowrap;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: #ffc107
}

.nav-item {
    margin: 0 13px
}

.bar-btn-links {
    border-radius: 0
}

.bar-btn-links figure {
    width: 26px;
    display: grid;
    align-content: center
}

.bar-btn-links figure img {
    filter: brightness(0) invert(1)
}

.latest-news-div {
    background: #0a0a0a;
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 100%
}

.slider-banner .item {
    height: 530px;
    position: relative
}

.slider-banner .item img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.slider-banner .cover {
    padding: 75px 0;
    position: absolute;
    height: auto;
    bottom: -81px;
    left: 0;
    display: inline-block
}

.slider-banner .cover .header-content {
    padding: 56px 0;
    overflow: hidden;
    width: 100%
}

.video-section {
    padding: 70px 0 40px 0;
    display: inline-block
}

.add-fiu {
    height: 480px;
    overflow: hidden;
    position: relative
}

.add-fiu::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: 0;
    left: 0;
    background: linear-gradient(1.35deg, rgba(0, 0, 0, 0.8) 17.74%, rgba(0, 0, 0, 0) 89.5%)
}

.slider-banner .item .cover .header-content h2 {
    font-weight: 300
}

.slider-banner.item .cover .header-content h1 {
    font-size: 56px;
    font-weight: 600;
    margin: 5px 0 20px;
    word-spacing: 3px
}

.slider-banner .item .cover .header-content h4 {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    color: #fff
}

.header-content h1 {
    color: #fff;
    font-size: 4.845rem;
    text-shadow: #000 1px 2px 22px
}

.header-content h1 span {
    font-size: 4.845rem;
    color: #ffc107
}

.header-content h2 {
    font-family: "GreatSejagad";
    font-size: 35px;
    color: red;
    font-size: 4.845rem
}

.header-content h2 span {
    font-size: 40px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500
}

.header-content .btn {
    background: #fff;
    color: #0a0a0a !important;
    margin-top: 20px;
    padding: 15px 30px;
    font-family: "DM Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 600
}

.owl-prev span {
    display: none
}

.owl-prev:after {
    content: "";
    font-family: "FontAwesome";
    position: absolute;
    font-size: 20px;
    color: #0a0a0a !important
}

.owl-next span {
    display: none
}

.owl-next:after {
    position: absolute;
    content: "";
    font-family: "FontAwesome";
    right: 20px;
    font-size: 20px;
    color: #0a0a0a !important
}

.statisci-players {
    position: relative;
    /* background: url(../images/bg-st.jpg) no-repeat; */
    background-size: cover;
    background-position: center 90px;
    padding: 40px 0
}

.statisci-players .owl-nav {
    position: absolute;
    right: 50px;
    bottom: 60px
}

.statisci-players .light-tsext01 {
    top: 15px !important;
    color: #fff !important;
    opacity: 15%
}

.details-divn {
    background: #0a0a0a;
    height: 380px;
    width: 100%;
    display: inline-block;
    align-content: center;
    border-radius: 30px 0 30px 0;
    position: relative;
    padding: 30px
}

.details-divn .rtyu {
    text-align: center;
    color: #fff
}

.details-divn .rtyu h2 {
    color: #ffc107;
    font-weight: 700;
    margin-top: 2px;
    font-size: 16px
}

.details-divn .rtyu h4 {
    color: #ffc107;
    font-weight: 700;
    margin-top: 2px;
    font-size: 18px
}

.details-divn h5 {
    color: #fff;
    font-size: 20px
}

.details-divn ul li {
    color: #fff;
    margin-bottom: 10px
}

.details-divn ul li span:last-child {
    background: #34393e;
    height: 38px;
    display: inline-block;
    padding: 8px 8px 8px 30px;
    width: 78%
}

.details-divn ul li span.rtus {
    background: #535cfb;
    width: 60px;
    padding: 8px;
    display: inline-block;
    position: relative
}

.details-divn ul li span.rtus:after {
    position: absolute;
    right: 0;
    border-left: solid 14px #34a853;
    content: "";
    top: 0;
    right: -32px;
    width: 33px;
    height: 38px;
    border-bottom: #34393e 38px solid
}

.items-plays figure {
    position: relative;
    z-index: 2;
    width: 250px;
    margin-bottom: 0 !important
}

.items-plays figure img {
    width: auto !important
}

.bootom-imgn {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: -10px;
    z-index: 2;
    margin: auto;
    display: block
}

.bootom-imgn img {
    object-fit: contain;
    width: 100%
}

.btn-member {
    background: #282828;
    color: #fff !important;
    border-radius: 0;
    transform: rotate(90deg);
    position: fixed;
    right: -69px;
    bottom: 50%;
    z-index: 99;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 12px
}

.btn-member:hover {
    background: #ffc107
}

.slider-banner .owl-item.active h1 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInDown;
    animation-delay: .3s
}

.slider-banner .owl-item.active h2 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInDown;
    animation-delay: .3s
}

.slider-banner .owl-item.active p {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInDown;
    animation-delay: .3s
}

.slider-banner .owl-item.active .baner014 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInRight;
    animation-delay: .3s
}

.slider-banner .owl-item.active .btn {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInUp;
    animation-delay: .3s
}

.slider-banner .owl-item.active .line {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInLeft;
    animation-delay: .3s
}

.slider-banner .owl-nav .owl-prev {
    position: absolute;
    left: 15px;
    top: 43%;
    opacity: 0;
    -webkit-transition: all .4s ease-out;
    transition: all .4s ease-out;
    background: rgba(0, 0, 0, .5) !important;
    width: 40px;
    cursor: pointer;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 0
}

.slider-banner .owl-nav .owl-prev span {
    font-size: 1.6875rem;
    color: #fff
}

.slider-banner .owl-nav .owl-prev:focus {
    outline: 0
}

.slider-banner .owl-nav .owl-prev:hover {
    background: #000 !important
}

.slider-banner .owl-nav .owl-next {
    position: absolute;
    right: 15px;
    top: 43%;
    opacity: 0;
    -webkit-transition: all .4s ease-out;
    transition: all .4s ease-out;
    background: rgba(0, 0, 0, .5) !important;
    width: 40px;
    cursor: pointer;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 0
}

.slider-banner .owl-nav .owl-next span {
    font-size: 1.6875rem;
    color: #fff
}

.slider-banner .owl-nav .owl-next:focus {
    outline: 0
}

.slider-banner .owl-nav .owl-next:hover {
    background: #000 !important
}

.slider-banner:hover .owl-prev {
    left: 0px;
    opacity: 1
}

.slider-banner:hover .owl-next {
    right: 0px;
    opacity: 1
}

.body-part {
    padding-top: 100px
}

.next-match-part {
    position: relative;
    /* background: url(../images/bg-dark01.jpg) no-repeat; */
    background-size: cover;
    padding: 100px 0
}

.next-match-part figure.bgh {
    position: absolute;
    left: 0;
    right: 0;
    top: 150px;
    margin: auto;
    display: block;
    width: 100%;
    text-align: center
}

.next-match-part figure.bgh img {
    opacity: 80%;
    mix-blend-mode: hard-light
}

.next-match-part .dots-bg {
    height: 100%;
    left: 0;
    position: absolute;
    top: -30px;
    width: 20%;
    /* background: #fff url(../images/dot.png); */
    z-index: -1
}

.comon-headding h2 {
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    color: #fff
}

.link-btn {
    color: #ffc107;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    transition: all .5s
}

.link-btn:hover {
    color: #0a0a0a
}

.mtches-divbnh01 .bodyu-divbn {
    /* background: url(../images/bg-stadium01.jpg) no-repeat; */
    height: 488px
}

.comon-match-sec .comon-match-div {
    padding: 30px 10px;
    display: inline-block;
    position: relative;
    width: 100%
}

.comon-match-sec .comon-match-div .cul-div {
    text-align: center;
    display: block
}

.comon-match-sec .comon-match-div .cul-div figure {
    position: relative;
    width: 110px;
    height: 100px;
    margin: auto !important;
    display: table !important;
    margin-bottom: 20px !important
}

.comon-match-sec .comon-match-div .cul-div figure img {
    border-radius: 8px;
    object-fit: cover;
    width: 100%;
    height: 100%
}

.comon-match-sec .comon-match-div h6 {
    font-weight: 600;
    font-size: 16px
}

.comon-match-sec .comon-match-div h6 span {
    color: #ffc107;
    font-weight: 500;
    font-family: "Kanit", sans-serif;
    font-style: inherit
}

.comon-match-sec .slider-banner .item {
    height: 500px;
    position: relative
}

.comon-match-sec .reuli-tc {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 100px;
    font-family: "Kanit", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #0a0a0a
}

.comon-match-sec .num-txet-divb {
    padding: 15px;
    width: 100%;
    margin-top: 0;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 400
}

.comon-match-sec .num-txet-divb h6 span {
    margin-top: 8px;
    color: #ffc107
}

.comon-match-sec .num-txet-divb .book-btn {
    background: #535cfb;
    border: solid 2px #ffc107 !important;
    color: #fff;
    border-radius: 0 !important;
    font-weight: 500;
    transition: all .5s
}

.comon-match-sec .num-txet-divb .book-btn:hover {
    color: #ffc107;
    background: none
}

.cul-div h6 {
    margin-top: 0
}

.vs-text {
    background: #535cfb;
    color: #fff;
    text-align: center;
    display: table;
    margin: auto;
    padding: 10px;
    font-size: 20px;
    font-family: "Roboto", sans-serif
}

.comon-leadborad .table {
    backdrop-filter: blur(4px)
}

.comon-leadborad .table th {
    background: #535cfb;
    color: #fff
}

.comon-leadborad .table td {
    border-color: rgba(255, 255, 255, .1098039216);
    color: #fff
}

.comon-leadborad .table tr {
    transition: all .5s
}

.comon-leadborad .table tr:hover {
    background: #535cfb;
    color: #fff
}

.strm-btn {
    background: #fff;
    color: #ffc107;
    border-radius: 0
}

.next-match05 {
    backdrop-filter: blur(4px);
    height: 480px;
    background: rgba(213, 213, 213, .031372549);
    padding: 30px
}

.mindle-heading {
    display: inline-block;
    width: 100%;
    position: relative;
    z-index: 1
}

.mindle-heading h5 {
    font-family: "Dancing Script", cursive;
    color: #ffc107;
    font-size: 28px
}

.mindle-heading h1 {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    color: #0a0a0a;
    font-size: 45px;
    margin-top: 10px;
    font-weight: 700
}

.mindle-heading span {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    color: #ffc107;
    font-weight: 700
}

.top-bg-backgrouh {
    background: #0a0a0a;
    padding: 21px 0;
    display: inline-block;
    width: 100%;
    height: 140px;
    background-size: cover;
    background-position: center;
    position: relative
}

.top-bg-backgrouh .conty {
    position: relative;
    z-index: 2
}

.top-bg-backgrouh figure {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.top-bg-backgrouh figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.top-list-turnament {
    display: inline-block;
    width: 100%;
    position: relative;
    background: #fff;
    margin-top: 0;
    padding: 50px 0
}

.top-list-turnament::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    background: #0a0a0a;
    width: 100%;
    height: 280px;
    top: 0
}

.top-list-turnament .main-hed01 {
    color: #fff
}

.top-list-turnament .btn-asee {
    color: #fff
}

.top-list-turnament .bgi-text {
    color: #fff !important;
    opacity: 15%
}

.top-list-turnament .bg-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    margin: auto
}

.top-list-turnament .bg-img img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.top-list-turnament .container {
    position: relative;
    z-index: 1
}

.top-list-turnament .top-imgn {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: -10px;
    z-index: 2
}

.top-list-turnament .top-imgn img {
    object-fit: contain;
    width: 100%
}

.right-windre {
    position: absolute;
    bottom: 30px;
    left: 0;
    z-index: 2
}

.right-windre img {
    width: 62%
}

.dots-bg {
    height: 100%;
    left: 0;
    position: absolute;
    top: -30px;
    width: 20%;
    /* background: #fff url(../images/dot.png); */
    z-index: -1
}

.comon-matchbn {
    display: inline-block;
    width: 100%;
    margin: 30px 0;
    background: #fff
}

.comon-matchbn .lega-text {
    font-weight: 1000;
    color: #fff
}

.comon-matchbn .btn-book-btn {
    background: #535cfb;
    color: #fff;
    border-radius: 0;
    width: 100%;
    height: 50px;
    line-height: 37px;
    font-size: 18px
}

.comon-matchbn .btn-book-btn svg {
    width: 20px;
    height: 20px
}

.comon-matchbn .cul-div {
    text-align: center;
    font-family: "Roboto", sans-serif
}

.comon-matchbn .date-ection-nu {
    /* background: url(../images/dat-bg.png) no-repeat; */
    width: 40px;
    height: 41px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    border-radius: 0;
    font-weight: 700
}

.comon-matchbn .date-ection-nu span {
    font-size: 16px
}

.comon-matchbn .top-sce-match {
    background: #535cfb;
    color: #fff;
    padding: 7px 15px
}

.comon-matchbn .topikn-div {
    padding: 0;
    display: inline-block;
    width: 100%;
    height: 320px
}

.comon-matchbn .more-details-div {
    background: #535cfb;
    padding: 0
}

.comon-matchbn .more-details-div h5 {
    font-family: "Roboto", sans-serif;
    font-size: 17px
}

.comon-matchbn .more-details-div h5 span {
    color: #ffc107;
    font-weight: 700
}

.comon-matchbn .more-details-div h6 {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    margin-top: 6px;
    color: #ffc107
}

.bg-vs04 {
    position: absolute;
    left: 0;
    right: 0;
    width: 71%;
    margin: auto
}

.about-sec-home {
    display: inline-block;
    /* background: #fff url(../images/40743172.png) no-repeat; */
    background-position: right top;
    background-size: 30%;
    width: 100%;
    padding-top: 50px;
    position: relative;
    padding-bottom: 0;
    min-height: 650px
}

.about-sec-home .right-ab0 {
    position: relative;
    top: 60px
}

.about-sec-home h5 {
    font-family: "Dancing Script", cursive;
    color: #ffc107;
    font-size: 28px
}

.about-sec-home h1 {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    color: #0a0a0a;
    font-size: 45px;
    font-weight: 700
}

.about-sec-home h1 span {
    color: #ffc107;
    font-family: "Kanit", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700
}

.about-sec-home h6 {
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    line-height: 28px;
    font-size: 18px
}

.about-sec-home h6 span:first-child {
    color: #f7ab00;
    text-transform: uppercase
}

.about-sec-home h6 span:last-child {
    color: #ffc107;
    text-transform: uppercase
}

.about-sec-home p {
    font-family: "Roboto", sans-serif;
    font-size: 16px
}

.btn-about {
    background: #0a0a0a;
    color: #fff !important;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    border-radius: 0 !important;
    font-size: 18px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: 200px;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: #a1a1a1 12px 12px 48px
}

.btn-about span {
    background: #535cfb;
    width: 40px;
    height: 40px;
    display: grid;
    align-content: center;
    justify-content: center;
    margin-right: 10px
}

.btn-about span svg {
    width: 30px;
    height: 30px;
    color: #fff
}

.match-result-div {
    display: inline-block;
    width: 100%;
    position: relative;
    padding: 40px 0
}

.match-result-div .container {
    position: relative;
    z-index: 1
}

.match-result-div::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    background: #0a0a0a;
    width: 100%;
    height: 280px;
    top: 0
}

.match-result-div .mindle-heading h5 {
    color: #0a0a0a
}

.match-result-div .mindle-heading h1 {
    text-shadow: none
}

.thballs {
    position: absolute;
    right: 0;
    bottom: 30px
}

.comon-results-div {
    display: inline-block;
    padding: 0 20px;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    min-height: 430px;
    align-content: end;
    background: #fff;
    box-shadow: rgba(0, 0, 0, .031372549) 5px 21px 15px
}

.comon-results-div .vds-resut {
    position: relative;
    z-index: 2
}

.comon-results-div .club-items {
    display: inline-block;
    width: 100%;
    position: relative
}

.comon-results-div .club-items figure {
    position: relative;
    z-index: 1;
    margin-top: 8px;
    width: 180px
}

.comon-results-div .club-items h5 {
    font-size: 18px;
    margin-top: 20px;
    position: relative;
    z-index: 1
}

.comon-results-div .club-items h5 span {
    font-size: 14px;
    text-transform: capitalize
}

.comon-results-div .row>div:last-child .club-items {
    flex-direction: row-reverse
}

.comon-results-div .row>div:last-child .club-items figure {
    margin-right: 15px
}

.comon-results-div .row>div:last-child .club-items h5 {
    margin-right: 15px
}

.comon-results-div .vds-resut {
    position: relative;
    z-index: 3
}

.comon-results-div .vds-resut .btn-wtch1 {
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 700
}

.comon-results-div .vds-resut .btn-wtch1 i {
    margin-right: 2px
}

.comon-results-div .vds-resut p {
    font-weight: 400;
    font-size: 11px
}

.comon-results-div .vds-resut p i {
    color: #ffc107
}

.comon-results-div .golas-divb h3 {
    color: #fff;
    font-family: "Anton", sans-serif;
    letter-spacing: 1.2px
}

.bodyu-divbn {
    display: inline-block;
    width: 100%;
    position: relative;
    padding: 30px
}

.leag-name {
    display: inline-block;
    width: 100%;
    padding: 5px 0;
    margin-bottom: 0;
    position: relative
}

.leag-name h2 {
    font-weight: 1000;
    position: relative;
    z-index: 2;
    padding: 15px 0
}

.leag-name h4 {
    padding: 10px 0 0 0;
    left: 0;
    right: 0;
    font-style: normal
}

.img-bg-du {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    display: block;
    z-index: 1;
    pointer-events: none
}

.img-bg-du img {
    text-align: center;
    display: block;
    margin: auto
}

.top-imgn {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    z-index: 2
}

.time-d05 {
    color: #fff !important;
    margin: 8px 0
}

.bg-img-left {
    position: absolute;
    left: -130px;
    bottom: 0;
    width: 30%
}

.shop-apge-div {
    display: inline-block;
    width: 100%;
    padding: 40px 0;
    background: #fff;
    position: relative
}

.shop-apge-div .container {
    position: relative;
    z-index: 1
}

.shop-apge-div .mindle-heading h1 {
    color: #0a0a0a;
    text-shadow: none !important
}

.red-bottrom {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    bottom: 0
}

.red-bottrom img {
    object-fit: contain;
    width: 100%
}

.comon-section1-shop {
    display: inline-block;
    width: 100%;
    height: 450px;
    position: relative;
    border: solid 1px rgba(0, 0, 0, .05);
    background: #fff;
    transition: all .5s
}

.comon-section1-shop:hover {
    border: solid 1px #ffc107
}

.comon-section1-shop .titel-text1 {
    font-size: 18px;
    margin-bottom: 8px;
    font-weight: 600
}

.comon-section1-shop .rat-text {
    color: #f7ab00;
    margin: 8px 0
}

.comon-section1-shop .price-text1 {
    font-size: 25px;
    font-weight: 600;
    color: #0a0a0a
}

.comon-section1-shop .price-text1 span {
    font-size: 17px;
    margin-right: 8px;
    color: #bbb
}

.comon-section1-shop .top-imgb-box {
    display: grid;
    width: 100%;
    height: 250px;
    overflow: hidden;
    position: relative;
    padding: 20px 30px;
    align-content: center
}

.comon-section1-shop .top-imgb-box .hover-list2 li {
    margin-bottom: 8px
}

.comon-section1-shop .top-imgb-box .hover-list2 li a {
    background: #535cfb;
    color: #fff
}

.comon-section1-shop .top-imgb-box figure {
    text-align: center
}

.comon-section1-shop .top-imgb-box figure img {
    object-fit: contain;
    width: 90%;
    height: 100%;
    object-position: center;
    margin: auto;
    transform: scale(1);
    transition: all .5s
}

.comon-section1-shop .top-imgb-box .hover-list2 {
    position: absolute;
    top: 10px;
    left: 10px;
    transform: translate(-54px, 0);
    transition: all .5s;
    z-index: 2
}

.comon-section1-shop:hover .hover-list2 {
    transform: translate(0)
}

.comon-section1-shop:hover figure img {
    transform: scale(1.1)
}

.our-small-details {
    display: grid;
    align-items: center;
    width: 100%;
    margin-top: 0;
    padding: 0;
    height: 292px;
    position: relative;
    overflow: hidden
}

.our-small-details .container {
    z-index: 1;
    position: relative
}

.our-small-details .bg-small-back02 {
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
    height: 800px;
    display: inline-block;
    width: 100%
}

.our-small-details .bg-small-back02 img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.our-small-details .comon-divbn {
    width: 100%
}

.our-small-details .comon-divbn figure {
    width: 69px;
    margin: 0
}

.our-small-details .comon-divbn .right-dibvb {
    width: 90%;
    padding-left: 10px
}

.our-small-details .comon-divbn .right-dibvb h2 {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 7px;
    font-size: 45px;
    color: #0a0a0a
}

.our-small-details .comon-divbn .right-dibvb h6 {
    color: #ffc107;
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase
}

.mediasection {
    padding: 40px 0;
    background: #fff;
    position: relative;
    margin-top: 50px
}

.mediasection .container {
    position: relative;
    z-index: 1
}

.mediasection::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    background: #535cfb;
    width: 100%;
    height: 280px;
    top: 0
}

.mediasection .light-tsext01 {
    top: 15px !important;
    color: #fff !important;
    opacity: 15%
}

.mediasection .mindle-heading h1 {
    color: #0a0a0a;
    text-shadow: none !important
}

.our-spocerder {
    background: #0a0a0a;
    background-repeat: no-repeat;
    background-position: right -180px, left top;
    background-size: 80%, 15%;
    height: 580px;
    margin-top: 50px;
    padding-top: 40px;
    position: relative
}

.our-spocerder .right-imgplya {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: inline-block
}

.our-spocerder .right-imgplya img {
    margin: auto;
    display: block
}

.our-spocerder h1 {
    font-weight: 700;
    font-size: 65px;
    color: #fff
}

.our-spocerder h1 span {
    color: #ffc107;
    font-weight: 700;
    font-size: 65px
}

.our-spocerder h6 {
    line-height: 28px;
    color: #ffc107;
    font-family: "Dancing Script", cursive;
    font-size: 28px
}

.our-spocerder h5 {
    font-size: 20px;
    line-height: 28px;
    color: #fff
}

.our-spocerder ul li {
    color: #fff;
    font-family: "Oswald", sans-serif;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-size: 25px
}

.our-spocerder ul li i {
    color: #ffc107
}

.our-spocerder .btn {
    display: flex;
    align-items: center;
    width: 200px;
    border-radius: 0;
    color: #fff !important;
    height: 48px;
    font-size: 18px;
    justify-content: center
}

.our-spocerder .subc {
    background: #ffc107 !important;
    color: #0a0a0a !important
}

.our-spocerder .joinst {
    background: #0a0a0a !important
}

.our-spocerder .light-tsext01 {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #979797 !important;
    color: #1c1c1c !important;
    left: 40px !important;
    right: inherit !important;
    top: 20px !important
}

.our-spocerder .joinst {
    background: #fff !important;
    color: #0a0a0a !important
}

.comon-links-divb05 {
    display: inline-block;
    height: 250px;
    overflow: hidden;
    width: 100%;
    border-radius: 8px
}

.comon-links-divb05 figure {
    width: 100%;
    height: 250px;
    margin: 0;
    position: relative
}

.comon-links-divb05 figure figcaption {
    position: absolute;
    width: 120px;
    height: 37px;
    border-radius: 0;
    background: #0a0a0a;
    text-align: center;
    bottom: 16px;
    left: 19px;
    color: #fff;
    display: grid;
    align-content: center;
    font-weight: 1000
}

.comon-links-divb05 figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

span.btn.btn-matech-r {
    position: absolute;
    bottom: -10px;
    left: 14px;
    background: #535cfb;
    border-radius: 0
}

.news-sectiondiv {
    background: #fff;
    padding: 40px 0;
    position: relative;
    margin-top: 50px
}

.news-sectiondiv .container {
    position: relative;
    z-index: 1
}

.news-sectiondiv::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    background: #f9f9f9;
    width: 100%;
    height: 280px;
    top: 0
}

.news-sectiondiv .mindle-heading h1 {
    text-shadow: none !important;
    color: #0a0a0a
}

.news-sectiondiv .light-tsext01 {
    top: 15px !important;
    color: #f2f7f3 !important;
    opacity: 15%
}

.crm-teams01 {
    position: relative;
    height: 300px;
    width: 100%;
    display: inline-block;
    overflow: hidden
}

.crm-teams01 .design {
    position: absolute;
    bottom: 20px;
    left: 20px
}

.crm-teams01 .design h5 {
    padding: 8px 15px;
    border-radius: 8px;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    font-size: 15px
}

.crm-teams01 .design p {
    background: #535cfb;
    color: #fff;
    border-radius: 8px;
    padding: 8px 15px;
    font-size: 12px;
    font-weight: 400;
    margin-top: 8px
}

.crm-teams01 figure {
    height: 300px;
    width: 100%;
    display: inline-block
}

.crm-teams01 figure img {
    transform: scale(1);
    object-position: top;
    transition: all .5s;
    object-fit: cover;
    width: 100%;
    height: 100%
}

.crm-teams01:hover figure img {
    transform: scale(1.1)
}

.big-headerpic {
    position: absolute;
    left: -20px;
    bottom: 0;
    width: 14%;
    display: inline-block;
    z-index: 2
}

.light-newd-section {
    background: #f2f7f3;
    padding: 120px 0;
    position: relative
}

.light-newd-section .mindle-heading h1 {
    text-shadow: none !important;
    color: #0a0a0a
}

.light-newd-section .big-news {
    display: inline-block;
    width: 100%;
    height: 450px;
    position: relative
}

.light-newd-section .big-news figure {
    height: 270px
}

.light-newd-section .big-news figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.light-newd-section .big-news .nes-date span {
    font-family: "Roboto", sans-serif
}

.light-newd-section .big-news h2 {
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 36px;
    margin: 10px 0;
    color: #0a0a0a
}

.light-newd-section .big-news p {
    font-family: "Roboto", sans-serif;
    font-size: 16px
}

.light-newd-section .light-tsext01 {
    top: 15px !important;
    color: #f2f7f3 !important;
    opacity: 15%
}

.comon-divb-post {
    display: flex;
    align-items: baseline;
    border-bottom: solid 1px #fff;
    padding: 20px 0
}

.comon-divb-post figure {
    width: 120px;
    height: 83px;
    overflow: hidden
}

.comon-divb-post figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.comon-divb-post .righty-divt {
    padding-left: 15px
}

.comon-divb-post .righty-divt h5 {
    font-family: "Roboto", sans-serif;
    margin-top: 8px;
    color: #0a0a0a
}

.sponcer-logo {
    background: #fff;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 30%;
    padding: 40px 0;
    position: relative
}

.sponcer-logo .light-tsext01 {
    top: 0px !important;
    color: #fff !important;
    opacity: 15%
}

.sponcer-logo .mindle-heading h1 {
    color: #0a0a0a;
    text-shadow: none !important
}

.sponcer-logo .mindle-heading h1 span {
    color: #ffc107
}

.sponcer-logo .corm-iteml {
    background: #fff;
    display: inline-block;
    width: 100%;
    padding: 30px;
    height: 150px;
    box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, .06);
    margin: 30px 0
}

.sponcer-logo .corm-iteml figure {
    width: 100%;
    height: 100%;
    display: grid;
    align-content: center
}

.rtck-img {
    position: absolute;
    top: -100px;
    right: 0;
    width: 22%
}

footer {
    background: #0a0a0a;
    padding: 60px 0 0 0;
    position: relative
}

footer hr {
    background: #b5b5b5;
    margin: 40px 0
}

footer .comonft-sec h5 {
    font-weight: 1000;
    font-size: 20px;
    margin-bottom: 30px
}

footer .comonft-sec ul li {
    color: #b5b5b5;
    margin-bottom: 18px;
    font-size: 16px
}

footer p {
    font-weight: 300px;
    font-family: "Roboto", sans-serif
}

footer .support-section svg {
    color: #ffc107;
    width: 40px;
    height: 40px
}

footer .support-section h6 {
    color: #fff;
    opacity: .2
}

footer .support-section a {
    color: #fff;
    text-decoration: none
}

footer .btn-socal-1 {
    color: #fff;
    background: #535cfb;
    display: inline-block;
    width: 38px;
    height: 38px;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    border-radius: 3px;
    margin-right: 10px
}

footer .comon-news {
    display: flex;
    align-items: flex-start;
    justify-content: space-between
}

footer .comon-news p {
    color: #fff;
    font-family: "Roboto", sans-serif
}

footer .comon-news figure {
    width: 90px;
    height: 100px;
    overflow: hidden
}

footer .comon-news figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

footer .comon-news .left-divbn {
    width: 60%
}

footer .comon-news .left-divbn .titel-text1 {
    color: #fff !important
}

.tg-btn {
    background: #ffd000
}

.spt {
    font-size: 15px;
    font-family: "Roboto", sans-serif
}

.right-fgo h4 {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase
}

.right-fgo li {
    margin-bottom: 10px
}

.right-fgo li a {
    color: #fff;
    font-size: 15px;
    font-family: "Roboto", sans-serif
}

.footer-div1 {
    background: #262626;
    padding: 15px 0;
    display: inline-block;
    width: 100%
}

.footer-div1 a {
    color: #fff;
    margin-left: 15px
}

.topls-paly h5 {
    font-weight: 1000;
    margin-bottom: 20px
}

.topls-paly ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap
}

.topls-paly .btn {
    color: #b5b5b5;
    border: solid 1px #bbb !important;
    border-radius: 50px;
    padding: 8px 19px;
    font-weight: 500;
    margin: 10px 8px
}

.banner-part {
    position: relative;
    z-index: 1
}

.banner-part p {
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    display: inline-block;
    color: #fff !important
}

.banner-part .baner014 {
    top: -30px;
    right: 10%;
    position: relative
}

header .navbar {
    padding-top: 10px !important;
    padding-bottom: 13px !important;
    background-color: whitesmoke;
}

header .navbar .bar-btn-links svg {
    width: 24px;
    height: 24px
}

.cul-div {
    display: inline-block;
    width: 100%
}

.cul-div figure {
    width: 80px;
    margin: auto;
    margin-bottom: 15px
}

.cul-div figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.bgi-text {
    position: absolute;
    left: 0;
    right: 0;
    top: -60px;
    font-style: normal;
    white-space: nowrap;
    font-size: 150px;
    font-weight: 800;
    opacity: 10%;
    text-align: center;
    text-transform: uppercase
}

.light-tsext01 {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #979797 !important;
    color: #0a0a0a !important
}

.bags-side {
    position: absolute;
    left: -80px;
    bottom: -80px;
    width: 21%;
    z-index: 2
}

.about-1imgn {
    position: absolute;
    bottom: -115px;
    right: 20px;
    width: 42%
}

.about-sec-home .light-tsext01 {
    left: 40px !important;
    right: inherit !important;
    top: 60px !important;
    color: #fff !important;
    opacity: 15%
}

.about-sec-home .container {
    position: relative;
    z-index: 2
}

.about-sec-home .text-abouy {
    padding-top: 80px
}

.match-result-div .light-tsext01 {
    color: #ffc107 !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff !important;
    top: 10px;
    opacity: 15% !important
}

.comon-poset {
    width: 30%;
    float: left
}

.comon-posrt {
    position: relative;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .1);
    padding-bottom: 30px;
    height: 570px
}

.comon-posrt .img-boxv {
    height: 300px;
    position: relative
}

.comon-posrt .img-boxv .daet01 {
    width: 80px;
    height: 80px;
    background: #535cfb;
    display: grid;
    color: #fff;
    text-align: center;
    align-content: center;
    font-size: 24px;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    position: absolute;
    bottom: -30px;
    right: 14px;
    font-weight: 700
}

.comon-posrt .img-boxv .daet01 small {
    font-size: 16px;
    font-weight: 500
}

.comon-posrt .img-boxv figure {
    height: 300px;
    overflow: hidden;
    margin: 0
}

.comon-posrt .img-boxv figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.comon-posrt .parar-delatsy {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 30px
}

.comon-posrt .parar-delatsy h6 {
    border-left: solid 2px #ffc107;
    padding-left: 10px
}

.comon-posrt .parar-delatsy h5 {
    font-family: "Oswald", sans-serif;
    font-style: normal;
    margin-top: 10px;
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 700
}

.comon-posrt p {
    font-family: "Oswald", sans-serif;
    font-size: 17px
}

.comon-posrt .user-dela06 {
    margin-top: 20px
}

.comon-posrt .admins {
    margin-top: 0;
    font-family: "Oswald", sans-serif
}

.comon-posrt .admins span {
    font-family: "Oswald", sans-serif;
    font-size: 18px
}

.comon-posrt .admins figure {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden
}

.comon-posrt .admins figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.comon-posrt .crom {
    font-family: "Oswald", sans-serif;
    font-size: 18px
}

.comon-posrt .crom i {
    font-size: 24px;
    margin-right: 5px
}

.middle-post {
    padding-bottom: 0;
    height: 300px
}

.middle-post .img-boxv {
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

.middle-post .img-boxv:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    background: #535cfb;
    opacity: 89%;
    content: "";
    height: 300px;
    display: inline-block
}

.middle-post .parar-delatsy {
    position: absolute;
    z-index: 2;
    color: #fff;
    padding-top: 15px
}

.middle-post .parar-delatsy h6 {
    border-left: solid 2px #0a0a0a
}

.right-post0 .img-boxv {
    height: 570px;
    top: 0;
    bottom: 0;
    overflow: hidden
}

.right-post0 .img-boxv figure {
    height: 570px
}

.right-post0 .parar-delatsy {
    position: absolute;
    z-index: 2;
    color: #fff;
    padding-top: 15px;
    bottom: 20px;
    width: 100%
}

.right-post0 .parar-delatsy h6 {
    border-left: solid 2px #ffc107
}

.right-post0 .daet01 {
    top: 20px;
    bottom: 0
}

.m-hefigh {
    height: 236px
}

.m-hefigh .img-boxv {
    height: 236px;
    top: 0;
    bottom: 0;
    overflow: hidden
}

.m-hefigh .img-boxv:after {
    background: #0a0a0a
}

.m-hefigh .img-boxv figure {
    height: 236px
}

.shop-apge-div .bgi-text {
    top: 20px !important;
    color: #fff !important;
    opacity: 15%
}

.cart-bthn {
    background: #535cfb;
    color: #fff;
    transition: all .5s;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-style: normal;
    font-size: 14px;
    width: 58%;
    margin: auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center
}

.cart-bthn svg {
    margin-left: 5px;
    width: 20px;
    height: 20px;
    display: grid
}

.cart-bthn:hover {
    background: #0a0a0a;
    color: #fff !important
}

.offers-divo {
    position: relative;
    padding: 80px 0
}

.offers-divo .mindle-heading h1 {
    color: #0a0a0a
}

.offers-divo .light-tsext01 {
    top: 15px !important;
    color: #fff !important;
    opacity: 15%
}

.tenis-meg figure {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 8px
}

.tenis-meg figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.tenis-meg h5 {
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 30px;
    color: #0a0a0a
}

.tenis-meg p {
    font-size: 17px
}

.tarinersp {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30%;
    z-index: 2
}

.cart-dropdown-ne {
    width: 310px !important;
    border: none;
    top: 120%;
    z-index: 4;
    right: 0 !important;
    left: inherit !important
}

.cart-dropdown-ne .comon-cart-ps {
    padding: 15px 19px 14px;
    margin: 0;
    border-bottom: solid 1px #ebebeb;
    width: 100%;
    display: inline-block
}

.cart-dropdown-ne .comon-cart-ps .products-sm-pic figure {
    height: 60px;
    width: 60px;
    overflow: hidden;
    margin: 0;
    border: solid 1px #f1f1f1;
    display: grid;
    align-items: center;
    padding: 5px
}

.sub-total-products h4 {
    color: #ffc107;
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal
}

.cart-drop-bn {
    width: 90%;
    margin: 0 auto;
    font-weight: 600;
    display: table;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    background: #101014;
    color: #fff !important;
    text-align: center;
    justify-content: center;
    border: solid 2px #101014 !important
}

.check-drop-bn {
    background: #535cfb;
    border: solid 2px #ffc107 !important;
    width: 90%;
    margin: 0 auto;
    font-weight: 600;
    display: table;
    color: #fff !important;
    margin-top: 10px;
    text-align: center;
    justify-content: center;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal
}

.sub-total-products {
    padding: 10px 15px
}

.com-div-md {
    background: #fff;
    padding-top: 25px;
    padding-bottom: 25px
}

.com-div-md h5 {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #101014
}

.login-div-modal .close {
    transform: none;
    position: absolute;
    right: 6px;
    top: 7px;
    border: none;
    background: none;
    font-size: 29px;
    background: #535cfb;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    display: grid;
    align-content: center
}

.login-modal-pn {
    padding: 10px 40px 30px 40px
}

.com-div-md p {
    font-family: "Oswald", sans-serif;
    font-size: 16px
}

.login-modal-pn .form-control {
    border: none;
    font-size: 15px;
    background: #f3f3f3;
    height: 45px;
    margin-bottom: 20px
}

.continue-bn {
    background: #535cfb;
    color: #fff !important;
    font-weight: 700;
    width: 100%;
    display: inline-block;
    box-shadow: 0 20px 30px 0 rgba(172, 172, 172, .4);
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: 1px;
    height: 45px;
    line-height: 31px
}

.continue-bn:hover {
    background: #0a0a0a
}

.login-div-modal .modal-content,
.login-div-modal .modal-header {
    background: none;
    border: none
}

.slider-banner .item .cover .header-content h2 {
    font-weight: 300;
    color: #ffc107
}

.slider-banner.item .cover .header-content h1 {
    font-size: 56px;
    font-weight: 600;
    margin: 5px 0 20px;
    word-spacing: 3px
}

.slider-banner .item .cover .header-content h4 {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    color: #fff
}

.header-content h1 {
    color: #fff;
    font-family: "Teko", sans-serif;
    font-size: 53px;
    font-weight: 600
}

.header-content h1 span {
    font-family: "Teko", sans-serif;
    color: #fff;
    font-size: 53px;
    font-weight: 600
}

.header-content h2 {
    font-family: "GreatSejagad";
    font-size: 35px;
    color: red;
    font-size: 4.845rem
}

.header-content h2 span {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 500
}

.header-content .btn {
    background: #535cfb;
    border-radius: 0 !important;
    font-size: 16px
}

.com-div-md p a {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    color: #ffc107;
    cursor: pointer;
    transition: all .5s
}

.com-div-md p a:hover {
    color: #0a0a0a !important
}

.cm-select-login label {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 14px;
    width: 92%
}

.login-div-modal .close svg {
    color: #fff !important;
    width: 24px;
    height: 24px;
    margin: auto;
    display: table
}

.contact-page .accordion-button:not(.collapsed) {
    background: #535cfb;
    color: #fff !important
}

.sub-main-banner {
    width: 100%;
    height: 140px;
    position: relative;
    padding-top: 27px;
    overflow: hidden;
    display: inline-block
}

.sub-main-banner .baner-imghi {
    position: absolute;
    height: 140px;
    display: inline-block;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    background: #101014
}

.sub-main-banner .baner-imghi img {
    object-position: top;
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 34%;
    mix-blend-mode: difference
}

.sub-main-banner .sub-banner {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 100%
}

.sub-main-banner h1 {
    font-family: "Teko", sans-serif;
    color: #fff;
    font-size: 43px;
    font-weight: 600;
    text-align: left !important;
    text-transform: uppercase
}

.sub-main-banner .breadcrumb {
    justify-content: start !important
}

.sub-main-banner .breadcrumb-item {
    font-size: 15px;
    margin-top: 0;
    color: #fff;
    text-transform: uppercase
}

.sub-main-banner .breadcrumb-item.active {
    color: #ffc107
}

.about-club-top {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-top: 40px
}

.about-club-top .big-img {
    width: 100%;
    height: 320px;
    border-radius: 15px;
    display: inline-block;
    overflow: hidden;
    margin: 0
}

.about-club-top .big-img img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.about-club-top .small-img {
    width: 250px;
    height: 200px;
    border-radius: 15px;
    display: inline-block;
    overflow: hidden;
    position: relative;
    top: 0;
    margin-left: auto;
    left: 15px;
    margin-top: -130px;
    margin-bottom: 50px
}

.about-club-top .samll-sub {
    color: #ffc107;
    font-family: "GreatSejagad";
    font-size: 25px
}

.our-history-div .nav-tabs .nav-item.show .nav-link,
.our-history-div .nav-tabs .nav-link.active {
    background: #535cfb;
    border: none !important;
    color: #fff !important
}

.our-history-div .nav-tabs .nav-item .nav-link {
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    color: #0a0a0a
}

.our-history-div .mna-bg {
    display: inline-block;
    width: 100%;
    background: #f2f2f2;
    padding: 50px 0
}

.our-history-div .nav-pills .nav-link,
.matech-div-main .nav-pills .nav-link {
    color: #0a0a0a;
    text-transform: uppercase
}

.comon-fild-ads1 figure {
    width: 40%;
    height: 250px;
    overflow: hidden;
    border-radius: 8px;
    margin: 0
}

.comon-fild-ads1 figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.left-history {
    width: 60%;
    padding-left: 30px
}

.left-history h2 {
    color: #000;
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    font-size: 24px
}

.left-history p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    margin-top: 10px;
    line-height: 30px
}

.our-history-div .nav-pills .nav-link.active,
.our-history-div .nav-pills .show>.nav-link,
.matech-div-main .nav-pills .show>.nav-link,
.matech-div-main .nav-pills .nav-link.active {
    background: #535cfb;
    color: #fff
}

.about-club-top h2 {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600
}

.about-club-top p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 30px
}

.big-img img,
.small-img img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.items-man figure {
    height: 180px;
    overflow: hidden;
    border-radius: 8px;
    margin: 0;
    width: 100%;
    display: inline-block;
    position: relative
}

.items-man figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.items-man .name {
    margin-top: 10px
}

.items-man .name h5 {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 17px;
    color: #0a0a0a
}

.items-man .name h5 span {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    margin-top: 5px;
    color: #ffc107
}

.achivement-div {
    display: inline-block;
    width: 100%;
    background: #f2f7f3;
    background-size: cover;
    min-height: 450px;
    margin-top: 50px;
    position: relative;
    padding: 60px 0
}

.our-mangent-sft {
    position: relative;
    padding: 80px 0
}

.our-mangent-sft .mangemnet-sf {
    margin-top: 80px
}

.our-mangent-sft .mindle-heading h1 {
    color: #0a0a0a
}

.our-mangent-sft .light-tsext01 {
    color: #fff !important;
    top: 30px
}

.achivement-div .achivent-slide {
    margin-top: 80px
}

.achivement-div .mindle-heading h1 {
    color: #0a0a0a
}

.achivement-div .light-tsext01 {
    color: #fff !important;
    top: 10px
}

.achiv-titel {
    text-align: center;
    color: #0a0a0a
}

.fixed-menu {
    background: #fff;
    position: fixed;
    top: 0;
    animation: slide-down .7s;
    left: 0;
    right: 0;
    box-shadow: 4px -86px 103px rgba(0, 0, 0, .9);
    z-index: 250
}

@keyframes slide-down {
    0% {
        transform: translateY(-100%)
    }

    100% {
        transform: translateY(0)
    }
}

@keyframes slide {
    0% {
        opacity: 0;
        transform: translateY(50%)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

.next-matches {
    position: relative;
    padding: 40px 0
}

.next-matches .light-tsext01 {
    top: 30px;
    color: #fff !important
}

.next-matches .mindle-heading h1 {
    color: #0a0a0a
}

.next-matches .comon-results-div {
    margin: 0 0 30px 0
}

.btn-get {
    background: #535cfb;
    width: 120px;
    height: 48px;
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    text-align: center;
    color: #fff !important;
    border-radius: 0;
    line-height: 32px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    box-shadow: #a1a1a1 12px 12px 48px
}

.btn-get svg {
    width: 20px;
    height: 20px
}

.dataTables_length,
.dataTables_info {
    display: none
}

.name-team figure {
    width: 50px;
    height: 50px;
    background: #fff;
    padding: 5px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 !important
}

.name-team span {
    font-weight: 500
}

.name-team .sm-iconj {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-right: 8px
}

.matches-seduels {
    position: relative;
    padding: 50px 0
}

.matches-seduels .nav-pills {
    position: relative;
    top: 90px
}

.matches-seduels .nav-pills .nav-link {
    border: 0;
    border-radius: .25rem;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    color: #0a0a0a;
    font-size: 20px;
    font-weight: 600
}

.matches-seduels .dataTables_filter {
    position: relative;
    top: -30px
}

.matches-seduels .nav-pills .nav-link.active,
.matches-seduels.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #ffc107
}

.matches-seduels .dataTables_wrapper {
    margin-top: 90px !important
}

.matches-seduels .light-tsext01 {
    top: 10px;
    color: #fff !important
}

.matches-seduels .mindle-heading h1 {
    color: #0a0a0a
}

.detaisl-matecchs .mindle-heading h1 {
    color: #0a0a0a
}

.matches-seduels iframe {
    margin-top: 30px;
    display: inline-block
}

.playssm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: solid 2px #fff;
    padding: 15px 0
}

.js-no1 {
    width: 40px;
    height: 40px;
    background: #535cfb;
    color: #fff;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 8px
}

.playssm h5 {
    color: #000
}

.playssm h5 span {
    color: #ffc107;
    font-size: 14px;
    margin-top: 3px
}

.comon-tema-n {
    display: inline-block;
    width: 100%;
    background: #f4f4f4;
    padding: 15px
}

.tem-payers .comon-heading {
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 30px
}

.detsils-ivbn ul {
    column-count: 2
}

.detsils-ivbn ul li {
    font-size: 18px;
    margin-bottom: 14px
}

.detsils-ivbn ul li span {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px
}

.vs-0 {
    font-weight: 600;
    color: #ffc107
}

.matches-seduels .table td {
    vertical-align: middle
}

.matches-seduels .table th {
    background: #0a0a0a;
    color: #fff !important;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    padding-top: 18px !important;
    padding-bottom: 18px !important
}

.listed-bn li {
    margin: 0 7px
}

.listed-bn li .active {
    color: #ffc107
}

.listed-bn li a {
    color: #0a0a0a;
    font-size: 24px;
    cursor: pointer
}

.list-serach-acd .accordion-button:not(.collapsed) {
    background-color: #f5f5f5 !important;
    box-shadow: none !important;
    color: #0a0a0a !important
}

.list-serach-acd .accordion-item {
    border: none;
    margin-top: 15px;
    font-family: "Kanit", sans-serif
}

.list-serach-acd .accordion-item {
    border: none;
    margin-top: 15px
}

.range-slider {
    width: 100%;
    text-align: center;
    position: relative
}

.range-slider .rangeValues {
    display: block
}

input[type=range] {
    -webkit-appearance: none;
    border: 1px solid #fff;
    width: 100%;
    position: absolute;
    left: 0
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #535cfb;
    margin-top: -4px;
    cursor: pointer;
    position: relative;
    z-index: 1
}

input[type=range]:focus {
    outline: none
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc
}

input[type=range]::-moz-range-track {
    width: 150px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px
}

input[type=range]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ee1d36
}

input[type=range]:-moz-focusring {
    outline: 1px solid #fff;
    outline-offset: -1px
}

input[type=range]::-ms-track {
    width: 150px;
    height: 5px;
    background: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    border-width: 6px 0;
    color: rgba(0, 0, 0, 0);
    z-index: -4
}

input[type=range]::-ms-fill-lower {
    background: #777;
    border-radius: 10px
}

input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px
}

input[type=range]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ffc107
}

input[type=range]:focus::-ms-fill-lower {
    background: #888
}

input[type=range]:focus::-ms-fill-upper {
    background: #ccc
}

.list-serach-acd .accordion-button {
    font-family: "Teko", sans-serif;
    font-style: normal;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    border: none !important;
    background: #f5f5f5 !important
}

.list-serach-acd .link-btn {
    background: #0a0a0a;
    color: #fff;
    width: 100%;
    border-radius: 0 !important;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-size: 18px;
    box-shadow: #a1a1a1 12px 12px 48px;
    margin-top: 25px
}

.list-serach-acd .link-btn svg {
    width: 20px;
    height: 20px;
    margin-left: 5px
}

.item.list-group-item {
    float: none;
    width: 100%;
    -ms-flex: 0 0 48%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    border: 0;
    height: 271px;
    border-radius: 8px;
    transition: all .5s
}

.comon-items-d1 {
    display: flex;
    justify-content: space-between;
    padding: 0;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    border: solid 1px rgba(0, 0, 0, .05) !important;
    border-radius: 0 !important;
    padding: 20px !important;
    transition: all .5s
}

.comon-items-d1:hover {
    border-color: #ffc107 !important
}

.item.list-group-item .left-div-list {
    width: 40%;
    position: relative;
    border-radius: 5px
}

.left-div-list figure {
    height: 230px;
    width: 230px;
    margin: auto !important;
    display: grid;
    align-content: center;
    justify-content: center
}

.comon-items-d1 figure img {
    transform: scale(1);
    transition: all .5s
}

.comon-items-d1:hover figure img {
    transform: scale(1.1)
}

.strat-r {
    background: #ff003d;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    position: absolute;
    left: 13px;
    top: 10px;
    z-index: 1
}

.item.list-group-item .right-list-div {
    padding-top: 0;
    padding-bottom: 0;
    width: 60%
}

.item.list-group-item .right-list-div {
    padding-left: 20px
}

.comon-items-d1 h6 {
    color: #ffc107;
    font-size: 20px;
    font-weight: 600;
    font-family: "Dancing Script", cursive;
    text-transform: capitalize
}

.comon-items-d1 .titel-product {
    font-size: 18px;
    color: #071f40;
    text-decoration: none;
    text-overflow: ellipsis;
    width: 100%;
    text-transform: capitalize;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    font-weight: 1000
}

.item .list-sort {
    font-size: 14px
}

.item {
    background: #fff
}

.item p {
    font-size: 14px;
    font-family: "Roboto", sans-serif
}

.comon-items-d1 h2 {
    font-size: 30px;
    color: #0a0a0a;
    font-family: "Roboto", sans-serif;
    font-weight: 700
}

.item h2 {
    margin-top: 10px
}

.view-products {
    background: #535cfb;
    color: #fff;
    font-style: normal;
    border-radius: 0;
    padding: 10px 15px;
    box-shadow: rgba(161, 161, 161, .6196078431) 1px 9px 27px
}

.view-products svg {
    margin-left: 5px;
    width: 20px;
    height: 20px
}

.grid-group-item .comon-items-d1,
.grid-group-item .left-div-list {
    width: 100%;
    position: relative;
    margin-bottom: 30px
}

.list-serach-acd .accordion-item {
    border: none;
    margin-top: 15px
}

.grid-group-item figure {
    width: 190px;
    height: 190px
}

.grid-group-item .right-list-div {
    display: inline-block;
    width: 100%
}

.list-serach-acd .form-check-input:checked {
    background-color: #ffc107;
    border-color: #ffc107
}

.listing-details-adds .accordion-button::after {
    content: "";
    background-image: none;
    font-family: "FontAwesome";
    transform: none !important
}

.listing-details-adds .accordion-button:not(.collapsed)::after {
    content: "";
    background-image: none;
    font-family: "FontAwesome";
    transform: none !important
}

.listing-details-adds .accordion-body {
    box-shadow: 0 3px 11px rgba(0, 0, 0, .07);
    padding: 15px !important
}

.listing-details-adds .accordion-body {
    padding-left: 0
}

.listing-details-adds .accordion-body .form-check {
    margin-bottom: 15px;
    position: relative
}

.listing-details-adds .accordion-body .form-check .form-check-input {
    opacity: 0
}

.listing-details-adds .accordion-body .form-check .form-check-input:checked+label:before {
    content: "";
    font-family: "fontAwesome";
    color: #ffc107;
    padding-top: 1px;
    font-size: 14px
}

.listing-details-adds .accordion-body .form-check label {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #101014;
    position: relative;
    padding-left: 10px
}

.listing-details-adds .accordion-body .form-check label:before {
    width: 20px;
    height: 20px;
    background: #fff;
    border: solid 2px #ffa800;
    content: "";
    display: grid;
    align-content: center;
    justify-content: center;
    left: -24px;
    top: 1px;
    position: absolute
}

.price-range-slider {
    width: 100%;
    padding: 10px 20px 0 0
}

.price-range-slider .range-value {
    margin: 0
}

.price-range-slider .range-value input {
    width: 100%;
    background: none;
    color: #000;
    font-size: 16px;
    font-weight: initial;
    box-shadow: none;
    border: none;
    font-weight: 600;
    margin: 10px 0 10px 0;
    font-family: "Roboto", sans-serif
}

.price-range-slider .range-value input:focus {
    border: none !important;
    outline: none !important
}

.price-range-slider .range-bar {
    border: none;
    background: #535cfb;
    height: 3px;
    width: 100%;
    margin-left: 8px;
    margin-bottom: 25px
}

.price-range-slider .range-bar .ui-slider-range {
    background: #ffc107
}

.price-range-slider .range-bar .ui-slider-handle {
    border: none;
    border-radius: 25px;
    background: #fff;
    border: 2px solid #ffc107;
    height: 17px;
    width: 17px;
    top: -0.52em;
    cursor: pointer
}

.price-range-slider .range-bar .ui-slider-handle+span {
    background: #ffc107
}

.products-slide-1 .mian-ppic {
    height: 387px;
    width: 380px;
    display: grid;
    justify-content: center;
    align-content: center;
    margin: auto;
    cursor: zoom-in
}

.menu-dl-right h2 {
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    font-size: 30px
}

.menu-dl-right h3 {
    color: #ffc107;
    font-weight: 600;
    margin: 8px 0;
    font-size: 35px
}

.menu-dl-right p {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    line-height: 30px;
    margin-bottom: 15px
}

.menu-dl-right h5 {
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    font-size: 30px
}

.bigh-shou {
    height: 450px !important
}

.sixe-menu-q li a {
    border: solid 1px #dbdbdb;
    color: #212529;
    padding: 10px;
    display: inline-block;
    cursor: pointer
}

.sixe-menu-q {
    margin: 15px 0
}

.sixe-menu-q li {
    position: relative;
    margin-right: 10px;
    display: inline-block
}

.sixe-menu-q .form-check {
    position: relative;
    width: 86px;
    height: 40px;
    padding-left: 0
}

.sixe-menu-q .form-check .form-check-input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 86px;
    height: 40px;
    margin: auto;
    opacity: 0;
    cursor: pointer
}

.sixe-menu-q .form-check .form-check-label {
    border: solid 1px #dbdbdb;
    color: #212529;
    padding: 10px;
    display: inline-block;
    cursor: pointer;
    font-family: "Oswald", sans-serif;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    width: 86px;
    text-align: center
}

.quantity-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    margin: 0;
    background: #fff;
    border-radius: 6px;
    padding: 10px;
    margin: 15px 0
}

.quantity-btn {
    background: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    margin: 0;
    padding: 0px 8px;
    cursor: pointer
}

.quantity-btn svg {
    width: 10px;
    height: 10px
}

.quantity-input {
    outline: none;
    user-select: none;
    text-align: center;
    width: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0);
    border: none;
    font-size: 18px;
    font-weight: 700
}

.items-details .form-group {
    width: 23%
}

.quantity-input::-webkit-inner-spin-button,
.quantity-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0
}

.quantity-input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    margin: 0
}

.cart-haedeing h2 {
    font-family: "Oswald", sans-serif;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600
}

.cart-haedeing h2 span {
    font-family: "Kanit", sans-serif;
    color: #ffc107;
    font-size: 20px;
    font-weight: 500
}

.comon-items-cart {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    padding-bottom: 25px;
    flex-wrap: wrap;
    box-shadow: #efefef 1px 2px 30px;
    padding: 20px
}

.comon-items-cart .left-section-div .products-cart1 ul {
    margin-top: 12px
}

.comon-items-cart .left-section-div {
    display: flex;
    align-items: flex-start;
    width: 67%
}

.comon-items-cart .left-section-div .products-cart1 h5 {
    font-family: "Kanit", sans-serif;
    color: #182025;
    font-weight: 500;
    font-size: 22px;
    text-transform: capitalize
}

.comon-items-cart .left-section-div .products-cart1 ul li span:first-child {
    width: 90px;
    display: inline-block;
    color: #ffc107
}

.comon-items-cart .left-section-div figure {
    width: 200px;
    height: 170px;
    overflow: hidden;
    margin: 0;
    display: grid;
    align-content: center
}

.comon-items-cart .crat-linl-pay {
    padding-top: 20px;
    display: grid;
    justify-content: flex-end;
    width: 32%;
    text-align: right
}

.comon-items-cart .crat-linl-pay h4 span {
    text-decoration: line-through;
    color: #bbb;
    font-size: 16px;
    font-weight: 400
}

.comon-items-cart .crat-linl-pay h4 {
    color: #ffc107;
    font-weight: 600
}

.comon-items-cart .crat-linl-pay h6 {
    color: #ff003d;
    font-family: "Signika", sans-serif;
    margin-top: 5px
}

.comon-items-cart .left-section-div .products-cart1 ul li {
    margin-bottom: 5px
}

.quantity-btn {
    background: #535cfb;
    color: #fff;
    border: none;
    outline: none;
    margin: 0;
    padding: 3px 11px;
    cursor: pointer
}

.total-count-div {
    box-shadow: #efefef 1px 2px 30px;
    padding: 30px;
    display: inline-block;
    width: 100%;
    border-radius: 8px
}

.quantity-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    margin: 0;
    background: #f7f7f7;
    border-radius: 6px;
    padding: 0 15px;
    height: 40px;
    margin: 15px 0
}

.total-count-div h4 {
    font-weight: 600;
    font-family: "Kanit", sans-serif
}

.share-links li a {
    margin: 0 10px;
    color: #071f40
}

.total-count-div .itemsl-list li span:first-child {
    color: #ffc107
}

.total-count-div .promo-code1 label {
    font-family: "Roboto", sans-serif;
    font-size: 19px;
    margin-bottom: 5px;
    font-weight: 600
}

.reconded-procuts h2 {
    font-family: "Oswald", sans-serif
}

.total-count-div .pay-listy li .price-bn {
    font-weight: 700;
    font-size: 24px
}

.total-count-div h3 span:first-child {
    font-weight: 400;
    color: #182025
}

.total-count-div h3 span {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: #ffc107
}

.total-count-div h3 {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.total-count-div .btncheck-btn {
    background: #535cfb;
    color: #fff;
    text-transform: uppercase;
    font-family: "Signika", sans-serif;
    display: inline-block;
    width: 100%
}

.comon-items-cart .left-section-div .products-cart1 {
    padding-left: 25px;
    padding-top: 20px;
    width: 70%
}

.total-count-div .itemsl-list li span {
    font-family: "Signika", sans-serif;
    font-size: 19px;
    font-weight: 700
}

.total-count-div .pay-listy li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize
}

.total-count-div .promo-code1 .btn {
    background: #222;
    color: #fff;
    margin-top: 10px
}

.total-count-div .pay-listy li span {
    margin-bottom: 10px;
    font-size: 18px
}

.total-count-div .pay-listy li .list-payt {
    color: #ffc107;
    font-family: "Roboto", sans-serif
}

.ad-cart-bn {
    background: #0a0a0a;
    width: 180px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    color: #fff !important;
    font-family: "Kanit", sans-serif;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: #a1a1a1 12px 12px 48px
}

.ad-cart-bn span {
    background: #535cfb;
    width: 40px;
    height: 40px;
    display: grid;
    align-content: center;
    justify-content: center;
    margin-right: 10px
}

.ad-cart-bn i {
    margin-right: 5px
}

.comment-user-div {
    display: flex;
    flex-wrap: wrap;
    border-bottom: solid 1px #f1f1f1;
    padding-bottom: 20px;
    padding-top: 20px
}

.comment-user-div p {
    font-size: 16px
}

.comment-user-div .us-pic {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 8px
}

.comment-user-div .us-pic img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.comment-user-div .user-dsl {
    width: 80%
}

.user-dsl h6 span {
    font-size: 13px;
    color: #ffc107;
    display: block;
    margin-top: 5px;
    margin-left: 8px;
    margin-right: 8px
}

.user-dsl h6 span {
    font-size: 13px;
    color: #ffc107;
    display: block;
    margin-top: 5px;
    margin-left: 8px;
    margin-right: 8px
}

.bottom-details h2 {
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    font-size: 30px;
    color: #0a0a0a
}

.bottom-details p {
    font-size: 16px;
    font-family: "Roboto", sans-serif
}

.submit-review .form-control {
    border: none;
    background: #f3f3f3;
    height: 50px;
    margin-bottom: 15px
}

.submit-review textarea {
    height: 150px !important;
    resize: none
}

.submit-review h5 {
    font-family: "Oswald", sans-serif;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    color: #0a0a0a
}

.submit-review .btn-about {
    width: 150px
}

.rating-box {
    display: inline-block
}

.rating-box .rating-container {
    direction: rtl !important
}

.rating-box .rating-container label {
    display: inline-block;
    margin: 0 0;
    color: #d4d4d4;
    cursor: pointer;
    font-size: 40px;
    transition: color .2s
}

.rating-box .rating-container input {
    display: none
}

.rating-box .rating-container label:hover,
.rating-box .rating-container label:hover~label,
.rating-box .rating-container input:checked~label {
    color: #ffc107
}

.related-produc {
    position: relative;
    padding: 60px 0;
    margin-top: 80px
}

.related-produc .mindle-heading h1 {
    color: #0a0a0a !important
}

.related-produc .light-tsext01 {
    color: #fff !important;
    top: 0px
}

.crat-btnh {
    background: #535cfb;
    color: #fff;
    border-radius: 0;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    height: 46px;
    box-shadow: #bfbdbd 8px 7px 48px
}

.submit-review .crat-btnh {
    width: 140px
}

.comon-section1-shop {
    display: inline-block;
    width: 100%;
    height: 450px;
    position: relative;
    border: solid 1px rgba(0, 0, 0, .05);
    background: #fff;
    transition: all .5s
}

.active .form-check-label {
    background: #101014;
    color: #fff !important
}

.blogs-page {
    padding: 70px 0
}

.blogs-page .comon-posrt {
    height: 600px;
    position: relative;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .1);
    padding-bottom: 30px
}

.blogs-page .comon-posrt .img-boxv {
    height: 300px;
    position: relative
}

.blogs-page .comon-posrt .img-boxv figure {
    height: 300px;
    overflow: hidden;
    margin: 0
}

.blogs-page .comon-posrt .img-boxv img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.blogs-page .comon-posrt .parar-delatsy {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 30px
}

.blogs-page .comon-posrt .user-dela06 {
    margin-top: 20px
}

.blogs-page .comon-posrt .admins {
    margin-top: 0;
    font-family: "Oswald", sans-serif
}

.blogs-page .comon-posrt .admins figure {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden
}

.blogs-page .comon-posrt .admins figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.blogs-page .comon-posrt .admins span {
    font-family: "Oswald", sans-serif;
    font-size: 18px
}

.blogs-page .comon-posrt .admins .crom {
    font-family: "Oswald", sans-serif;
    font-size: 18px
}

.blogs-page .comon-posrt p {
    font-family: "Oswald", sans-serif;
    font-size: 17px
}

.blogs-page .comon-heading {
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    color: #0a0a0a
}

.blogs-page .daet01 {
    width: 80px;
    height: 80px;
    background: #535cfb;
    display: grid;
    color: #fff;
    text-align: center;
    align-content: center;
    font-size: 24px;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    position: absolute;
    bottom: -30px;
    right: 14px;
    font-weight: 700
}

.blogs-page .daet01 small {
    font-size: 16px;
    font-weight: 500
}

.blogs-page .comon-posrt .parar-delatsy h6 {
    border-left: solid 2px #ffc107;
    padding-left: 10px
}

.blogs-page .comon-posrt .parar-delatsy h5 {
    font-family: "Oswald", sans-serif;
    font-style: normal;
    margin-top: 10px;
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 700
}

.blog-post figure {
    width: 100%;
    height: 320px;
    overflow: hidden
}

.blog-post figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.blog-post ul li {
    margin: 0 10px
}

.blog-post p {
    font-size: 16px
}

.blog-post .comon-heading {
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    font-style: normal;
    color: #0a0a0a;
    margin-bottom: 15px
}

.comon-com-div figure {
    width: 63px;
    height: 63px;
    overflow: hidden;
    border-radius: 50%
}

.comon-com-div figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.comment-sec-part h2 {
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    font-style: normal;
    font-size: 40px
}

.comment-text {
    width: 89%
}

.comment-text p {
    font-size: 16px;
    font-family: "Roboto", sans-serif
}

.comment-text span {
    font-size: 13px;
    font-weight: 600;
    color: #ffc107
}

.leave-sec-part h2 {
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    font-style: normal;
    font-size: 41px
}

.leave-sec-part .form-control,
.conatct-form-div .form-control {
    border: none;
    background: #f1f1f1;
    height: 45px
}

.leave-sec-part .form-group {
    margin-top: 20px
}

.leave-sec-part textarea,
.conatct-form-div textarea {
    height: 100px !important;
    resize: none
}

.subimt-comment {
    background: #535cfb;
    font-weight: 600;
    font-family: "Kanit", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    height: 45px;
    line-height: 34px;
    color: #fff;
    border-radius: 0;
    width: 150px;
    box-shadow: #a1a1a1 12px 12px 48px
}

.category-div,
.job-ds-div {
    border: solid 1px rgba(0, 0, 0, .05);
    padding: 30px;
    display: inline-block;
    width: 100%
}

.category-div {
    border: solid 1px rgba(0, 0, 0, .05);
    padding: 30px;
    display: inline-block;
    width: 100%
}

.advertisement_div {
    border: solid 1px rgba(0, 0, 0, .05);
    padding: 10px;
    display: inline-block;
    width: 100%
}

.category-div h2 {
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal;
    font-size: 30px
}

.category-div ul li {
    border-bottom: solid 1px #fff;
    margin-bottom: 10px;
    padding-bottom: 10px
}

.category-div ul li a {
    text-decoration: none;
    color: #212529;
    font-weight: 600;
    transition: all .5s;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif
}

.recent-post-div {
    display: inline-block;
    width: 100%
}

.recent-post-div h2 {
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    font-style: normal;
    font-size: 30px
}

.recent-post-div figure {
    width: 220px;
    height: 150px;
    overflow: hidden;
    margin: 0;
    transform: scale(1);
    border-radius: 8px;
    margin-right: 20px;
}

.recent-post-div figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.recent-post-div h5 {
    width: 100%;
    color: #212529;
    font-size: 17px;
    line-height: 25px;
    font-weight: 600;
    transition: all .5s;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif
}

.recent-post-div-insiide a {
    text-decoration: none;
    border-bottom: solid 1px #fff;
    display: inline-block;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px
}


.crat-btnh {
    background: #535cfb;
    color: #fff;
    border-radius: 0;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    height: 46px;
    box-shadow: #bfbdbd 8px 7px 48px
}

.crat-btnh i {
    margin-right: 5px
}

.submit-review .crat-btnh {
    width: 140px
}

.palyerspage {
    padding: 70px 0;
    position: relative
}

.palyerspage .mindle-heading h1 {
    color: #0a0a0a
}

.palyerspage .light-tsext01 {
    color: #fff !important;
    top: 20px
}

.active .form-check-label {
    background: #0a0a0a;
    color: #fff !important
}

.py-us {
    width: 250px;
    height: 300px;
    overflow: hidden;
    border-radius: 8px
}

.py-us img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.tesmpa {
    position: relative;
    z-index: 1;
    top: -70px;
    width: 100px;
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    margin-left: 20px;
    box-shadow: #d9d9d9 1px 8px 29px
}

.information-crm ul {
    column-count: 2
}

.information-crm ul li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-family: "Roboto", sans-serif;
    background: #f1f1f1
}

.information-crm ul .dert {
    background: #535cfb;
    color: #fff;
    padding: 8px;
    margin-right: 10px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    width: 180px;
    display: inline-block;
    position: relative
}

.details-platyd h1 {
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal
}

.details-platyd h3 {
    font-family: "Oswald", sans-serif;
    font-style: normal;
    font-size: 20px;
    color: #ffc107
}

.details-platyd h4 {
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    font-style: normal
}

.career-div01 .accordion-button:not(.collapsed) {
    background: #0a0a0a;
    color: #fff !important;
    font-family: "Oswald", sans-serif;
    font-style: normal;
    text-transform: uppercase
}

.listing-details-adds .accordion-body {
    box-shadow: 0 3px 11px rgba(0, 0, 0, .07);
    padding: 15px !important
}

.listing-details-adds .accordion-body {
    padding-left: 0
}

.listing-details-adds .accordion-body .form-check {
    margin-bottom: 15px;
    position: relative
}

.listing-details-adds .accordion-body .form-check .form-check-input {
    opacity: 0
}

.listing-details-adds .accordion-body .form-check .form-check-input:checked+label:before {
    content: "";
    font-family: "fontAwesome";
    color: #ffc107;
    padding-top: 1px;
    font-size: 14px
}

.listing-details-adds .accordion-body .form-check label {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #101014;
    position: relative;
    padding-left: 10px
}

.listing-details-adds .accordion-body .form-check label:before {
    width: 20px;
    height: 20px;
    background: #fff;
    border: solid 2px #ffa800;
    content: "";
    display: grid;
    align-content: center;
    justify-content: center;
    left: -24px;
    top: 1px;
    position: absolute
}

.contact-page {
    padding: 70px 0
}

.faq-section1 h2 {
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    color: #0a0a0a
}

.faq-section1 p {
    font-family: "Roboto", sans-serif;
    text-transform: capitalize
}

.contact-use-div .from-group {
    margin-bottom: 25px
}

.contact-use-div .from-group .custom-select-wrapper {
    background-color: #f7f7f7;
    border: none !important;
    height: 45px;
    font-size: 14px;
    width: 100%;
    padding: 0 10px
}

.contact-use-div .from-group label {
    font-family: "DM Sans", sans-serif;
    font-weight: 600;
    color: #0a0a0a;
    margin-bottom: 10px;
    display: block;
    font-size: 16px
}

.contact-use-div .comon-btn {
    background: #535cfb;
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    height: 45px;
    line-height: 34px;
    color: #fff;
    border-radius: 0;
    width: 150px;
    box-shadow: #a1a1a1 12px 12px 48px
}

.contact-use-div textarea.form-control {
    height: 100px;
    resize: none
}

.contact-use-div .form-control {
    background-color: #f7f7f7;
    border: none !important;
    height: 45px;
    font-size: 14px;
    border-radius: 0
}

.contact-use-div h2 {
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    color: #0a0a0a
}

.contact-use-div ul li {
    margin-bottom: 18px
}

.contact-use-div ul li span:first-child {
    margin-bottom: 5px;
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    color: #ffc107;
    font-size: 20px
}

.location-div01 {
    position: relative;
    padding: 60px 0 0 0;
    margin-top: 80px
}

.location-div01 .mindle-heading h1 {
    color: #0a0a0a
}

.location-div01 .light-tsext01 {
    color: #fff !important
}

.quantity-field {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    margin: 0 auto;
    margin-top: 15px
}

.quantity-field .value-button {
    border: 1px solid #0a0a0a;
    margin: 0px;
    width: 40px;
    height: 100%;
    padding: 0;
    background: #0a0a0a;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 18px
}

.quantity-field .value-button:hover {
    background: #ffc107
}

.quantity-field .value-button:active {
    background: #ffc107
}

.quantity-field .decrease-button {
    margin-right: -4px;
    border-radius: 8px 0 0 8px
}

.quantity-field .increase-button {
    margin-left: -4px;
    border-radius: 0 8px 8px 0
}

.quantity-field .number {
    display: inline-block;
    text-align: center;
    border: none;
    border-top: 1px solid #0a0a0a;
    border-bottom: 1px solid #0a0a0a;
    margin: 0px;
    width: 40px;
    height: 100%;
    line-height: 40px;
    font-size: 14pt;
    box-sizing: border-box;
    background: #fff;
    font-family: calibri
}

.quantity-field .number::selection {
    background: none
}

.banner-part .carousel-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: top
}

.thanksoy-page h1 {
    font-weight: 600;
    text-transform: uppercase;
    font-style: normal
}

.thanksoy-page h1 span {
    color: #ffc107
}

.thanksoy-page p {
    font-size: 18px;
    font-family: "Roboto", sans-serif
}

.ad-fm label {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600
}

.ad-fm .comon-heading {
    font-family: "Oswald", sans-serif;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    color: #0a0a0a
}

.ad-fm .form-select {
    background-color: #f1f1f1;
    height: 45px;
    border: none
}

.ad-fm .form-group {
    position: relative;
    margin: 13px 0
}

.ad-fm .form-group label {
    font-weight: 600;
    margin-bottom: 8px
}

.ad-fm .form-control {
    font-weight: 300;
    height: 45px;
    color: #888;
    background-color: #f1f1f1;
    border: none
}

.ceck-out-right-div {
    display: inline-block;
    width: 100%;
    border: solid 1px rgba(0, 0, 0, .05);
    padding: 30px
}

.ceck-out-right-div h2 {
    font-family: "Oswald", sans-serif;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    color: #0a0a0a
}

#ac-2 {
    display: none
}

.left-sec-d2 {
    margin-left: 15px
}

.end-date,
.cvv {
    display: flex;
    place-items: center
}

.cvv .form-control {
    width: 60%;
    margin-right: 8px
}

.end-date .form-select {
    margin-right: 5px;
    font-size: 13px
}

.paymeny h4 {
    font-weight: 600;
    margin-bottom: 8px;
    font-family: "Roboto", sans-serif;
    font-size: 16px
}

.checkout-table th {
    background: #0a0a0a;
    color: #fff;
    font-family: "Roboto", sans-serif;
    text-transform: capitalize
}

.form-wizard-next-btn {
    background: #101014;
    color: #fff !important;
    border-radius: 0 !important;
    font-family: "Kanit", sans-serif;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    height: 50px;
    width: 100%;
    line-height: 40px;
    box-shadow: #a1a1a1 12px 12px 48px
}

.price-am,
.delivery-am,
.discount-am {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    margin-bottom: 15px
}

.total-price span {
    font-weight: 700;
    font-size: 30px
}

.delivery-am span {
    font-size: 15px;
    font-weight: 500;
    color: #ffc107
}

.quick-link ul li {
    display: flex;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: normal;
    align-items: center
}

.quick-link ul li i {
    font-size: 35px;
    margin-right: 14px;
    color: #ffc107
}

.side-media li a {
    background: #101014;
    color: #fff;
    padding: 8px 12px;
    border-radius: 8px;
    display: inline-block
}

.side-media li {
    margin: 0 5px
}

.head-contact p {
    font-size: 16px
}

.close-menu {
    border: none;
    color: #323657;
    background: none !important;
    position: absolute;
    right: 21px;
    top: -9px
}

.side-media {
    list-style-type: none;
    margin-top: 15px;
    display: flex
}

.close-menu svg {
    width: 30px;
    height: 30px
}

header .navbar .bar-btn-links svg {
    width: 24px;
    height: 24px
}

.custom-select-wrapper {
    position: relative;
    display: inline-block;
    user-select: none;
    min-width: max-content
}

.custom-select-wrapper select {
    display: none
}

.custom-select {
    position: relative;
    display: inline-block;
    width: 100%
}

.custom-select-trigger {
    position: relative;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    cursor: pointer;
    padding: 0 20px 0 0;
    font-size: 16px;
    height: 45px;
    line-height: 40px;
    margin: auto;
    display: block
}

.custom-select-trigger:after {
    position: absolute;
    display: block;
    content: "";
    font-family: "fontAwesome";
    top: 0;
    right: 0;
    margin-top: 0;
    transition: all .4s ease-in-out;
    font-size: 17px
}

.custom-options {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    min-width: 140px;
    margin: 15px 0;
    border: 1px solid #b5b5b5;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0 2px 1px rgba(0, 0, 0, .07);
    background: #fff;
    transition: all .4s ease-in-out;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-15px)
}

.custom-select.opened .custom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: translateY(0)
}

.custom-options:before {
    position: absolute;
    display: block;
    content: "";
    bottom: 100%;
    left: 25px;
    width: 7px;
    height: 7px;
    margin-bottom: -4px;
    border-top: 1px solid #b5b5b5;
    border-left: 1px solid #b5b5b5;
    background: #fff;
    transform: rotate(45deg);
    transition: all .4s ease-in-out
}

.option-hover:before {
    background: #f9f9f9
}

.custome-am {
    position: relative
}

.wizard-form-error-div {
    display: none
}

.wizard-form-error-div h6 {
    background: none;
    color: #d70b0b
}

.custom-option {
    position: relative;
    display: block;
    padding: 0 22px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    line-height: 47px;
    cursor: pointer;
    transition: all .4s ease-in-out
}

.custom-option:first-of-type {
    border-radius: 4px 4px 0 0
}

.custom-option:last-of-type {
    border-bottom: 0;
    border-radius: 0 0 4px 4px
}

.custom-option:hover,
.custom-option.selection {
    background: #f9f9f9
}

.top-part-section {
    background: #0a0a0a;
    padding: 15px 25px;
    position: relative
}

.top-part-section h4 {
    font-family: "DM Sans", sans-serif;
    font-weight: 1000
}

.top-part-section h4 span {
    font-family: "DM Sans", sans-serif
}

.top-part-section p {
    font-family: "DM Sans", sans-serif
}

.top-part-section p span {
    font-family: "DM Sans", sans-serif;
    font-weight: 900
}

.top-part-section .img-01-lo {
    position: absolute;
    top: 0;
    left: 0;
    background: #535cfb;
    padding: 10px
}

.top-part-section .btn-live {
    background: #535cfb;
    color: #fff
}

.top-part-section .flexrt .logui {
    display: inline-block;
    width: 68px;
    margin-right: 15px
}

.top-part-section .flexrt .logui img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.top-part-section .flexrt h5 span {
    font-weight: 600;
    font-family: "Oswald", sans-serif;
    font-size: 25px;
    margin-left: 10px
}

.top-part-section .flexrt h5 small {
    color: #bbb;
    font-size: 14px
}

.top-part-section .imgou {
    width: 100%;
    height: 90px;
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 10px
}

.matcheds .nav-pills .nav-link.active,
.matcheds .nav-pills .show>.nav-link {
    background: #ffc107
}

.matcheds .nav-pills .nav-link {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    background: #ebebeb;
    color: #0a0a0a;
    padding: 10px 30px
}

.cominu-feterx .form-group label {
    font-family: "DM Sans", sans-serif;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 600
}

.cominu-feterx .custom-select-wrapper {
    border: solid 1px #ededed;
    width: 100%;
    height: 45px;
    padding: 0 11px
}

.win-text {
    background: #0a0a0a;
    color: #fff;
    padding: 10px 0;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    margin-bottom: 15px
}

.matcheds h3 {
    color: #0a0a0a;
    font-size: 40px;
    font-family: "Teko", sans-serif;
    color: #0a0a0a;
    font-weight: 700;
    text-transform: uppercase
}

.upcoming .top-part-section {
    background: #fff !important;
    border: solid 1px #efefef
}

.cm-text-n {
    background: #0a0a0a;
    border-radius: 5px;
    padding: 18px;
    height: 450px
}

.cm-text-n ul li {
    margin-bottom: 20px
}

.cm-text-n ul li .icon-c {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #fff;
    color: #fff;
    margin-right: 18px
}

.cm-text-n ul li .text-c {
    color: #fff;
    width: 78%;
    font-family: "DM Sans", sans-serif;
    text-transform: uppercase
}

.cm-text-n ul li .text-c small {
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    font-weight: 600;
    text-transform: capitalize
}

.comon-vedo-divu {
    cursor: pointer
}

.comon-vedo-divu .vio-imghj {
    height: 190px;
    position: relative;
    margin-bottom: 15px
}

.comon-vedo-divu .vio-imghj img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.comon-vedo-divu .vio-imghj .btn {
    position: absolute;
    bottom: -10px;
    left: 8px;
    background: #535cfb;
    font-size: 13px
}

.comon-vedo-divu .trecty {
    padding: 0 10px 0 5px
}

.comon-vedo-divu .trecty h5 {
    font-weight: 1000;
    color: #0a0a0a;
    font-size: 18px;
    margin-top: 26px
}

/* .comon-vedo-divu .trecty p {
    margin-top: 20px
} */

.video-container iframe {
    width: 100%;
    height: 450px
}

.leadr-board {
    padding: 40px 0 50px 0
}

.leadr-board .comon-teams-poins {
    border: solid 2px #f5f5f5;
    padding: 15px
}

.leadr-board .comon-teams-poins span.noi {
    background: #535cfb;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
    font-weight: 1000
}

.leadr-board .comon-teams-poins h5 {
    font-weight: 800;
    text-align: center
}

.leadr-board .comon-teams-poins h5 span {
    font-size: 20px
}

.leadr-board .comon-teams-poins .linrt {
    margin-top: 8px
}

.leadr-board .comon-teams-poins .linrt li {
    border: solid 2px #ffc107;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
    font-weight: 1000;
    color: #ffc107
}

.leadr-board .comon-teams-poins .linrt .lo {
    border-color: red;
    color: red
}

.leadr-board .comon-teams-poins figure {
    display: table;
    width: 100px;
    margin: 15px auto
}

.leadr-board .comon-teams-poins figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.leadr-board .comon-teams-poins ul {
    padding: 0
}

.leadr-board .comon-teams-poins ul li {
    text-align: center
}

.leadr-board .comon-teams-poins ul li span:first-child {
    display: block;
    font-weight: 1000;
    color: #0a0a0a
}

.leadr-board .btn-asee {
    background: #535cfb;
    padding: 10px 30px;
    display: table;
    margin: auto;
    margin-top: 40px;
    font-weight: 1000
}

.leadr-board .btn-asee i {
    color: #0a0a0a
}

.leadr-board .nav-pills .nav-link.active,
.leadr-board .nav-pills .show>.nav-link {
    background: #0a0a0a;
    color: #fff !important
}

.leadr-board .nav-item {
    margin: 0 5px
}

.leadr-board .nav-pills .nav-link {
    font-size: 18px;
    font-weight: 1000;
    color: #0a0a0a;
    font-style: normal;
    border: solid 1px #e3e3e3;
    text-transform: uppercase
}

.big-viuy video {
    width: 100%
}

.helights {
    position: relative;
    padding: 40px 0;
    margin-top: 40px
}

.helights ul {
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden
}

.helights .container {
    position: relative;
    z-index: 1
}

.helights::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    background: #535cfb;
    width: 100%;
    height: 280px;
    top: 0
}

.voi-paly {
    background: #0a0a0a;
    display: flex;
    height: 120px;
    margin-bottom: 15px
}

.voi-paly .imgh-voi {
    width: 40%;
    height: 120px
}

.voi-paly .imgh-voi img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.voi-paly .img-detau {
    width: 60%;
    padding-left: 12px;
    padding-top: 10px
}

.voi-paly .img-detau h5 {
    color: #fff;
    font-size: 16px
}

.voi-paly .img-detau p {
    color: #b5b5b5;
    font-size: 14px;
    margin-top: 15px
}

.features-serise {
    margin: 40px 0
}

.features-serise .items-ciu {
    position: relative
}

.features-serise .items-ciu .img-oudi {
    height: 207px;
    position: relative
}

.features-serise .items-ciu .img-oudi img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.features-serise .items-ciu .img-oudi .btn-ofu {
    position: absolute;
    bottom: -10px;
    left: 8px;
    background: #535cfb;
    font-size: 13px;
    font-weight: 1000
}

.features-serise h5 {
    font-weight: 1000;
    font-size: 16px;
    color: #0a0a0a;
    margin-top: 25px
}

.features-serise p {
    color: #0a0a0a;
    margin-top: 10px
}

.comr-teams .fli-iom {
    width: 70px;
    display: inline-block;
    margin-right: 15px
}

.comr-teams h5 {
    font-weight: 1000;
    color: #0a0a0a
}

.comr-teams h5 span {
    margin-top: 5px
}

.comr-teams h5 span small {
    font-size: 14px;
    color: #bbb
}

.wintc {
    color: #ffc107
}

.vhs {
    width: 30px;
    margin: 11px auto
}

.bigo1-imgh {
    height: 530px
}

.bigo1-imgh figure {
    height: 530px
}

.mtches-divbnh01 .video-section {
    padding: 40px 0
}

.pressc {
    position: relative
}

.pressc .container {
    position: relative;
    z-index: 1
}

.pressc::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 280px;
    background: #ffc107
}

.hpressc {
    position: relative
}

.hpressc .container {
    position: relative;
    z-index: 1
}

.hpressc::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 280px;
    background: #0a0a0a
}

.startpage {
    padding: 40px 0
}

.startpage h3 {
    font-size: 40px;
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal
}

.startpage thead th {
    background: #0a0a0a;
    color: #fff;
    font-weight: 1000;
    text-transform: uppercase;
    text-align: center !important
}

.startpage thead th:first-child {
    width: 80px !important
}

.startpage td {
    vertical-align: middle;
    text-align: center !important
}

.startpage .iconm {
    font-weight: 1000;
    color: #0a0a0a
}

.startpage .anmuir {
    font-weight: 1000
}

.standing01 .sm-iconj {
    width: 40px;
    height: 40px;
    border-radius: 0;
    display: grid;
    place-items: center
}

.standing01 .sm-iconj img {
    vertical-align: middle
}

.startpage .nav-pills .nav-link,
.tesmas .nav-pills .nav-link,
.top-runs-div .nav-pills .nav-link,
.plyaers-divbn-details .nav-pills .nav-link {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    background: #ebebeb;
    color: #0a0a0a;
    padding: 10px 30px;
    font-style: normal
}

.top-list-turnament .nav-pills .nav-link {
    background: #fff;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #0a0a0a;
    padding: 10px 30px;
    font-style: normal
}

.startpage .nav-pills .nav-link.active,
.startpage .nav-pills .show>.nav-link,
.tesmas .nav-pills .nav-link.active,
.tesmas .nav-pills .show>.nav-link,
.top-list-turnament .nav-link.active,
.top-list-turnament .show>.nav-link,
.top-runs-div .nav-link.active,
.top-runs-div .show>.nav-link,
.plyaers-divbn-details .nav-link.active,
.plyaers-divbn-details .show>.nav-link {
    background: #ffc107
}

.tet-div .nav-link.active,
.top-runs-div .show>.nav-link {
    background: #0a0a0a !important;
    color: #fff
}

.wickt-iv .nav-pills .nav-link {
    background: #fff !important
}

.wickt-iv .nav-link.active,
.wickt-iv .show>.nav-link {
    background: #0a0a0a !important;
    color: #fff
}

.tesmas {
    padding: 40px 0
}

.career-div01 .accordion-item {
    margin-top: 30px
}

.most-playerd-vides {
    border: solid 1px #ededed;
    padding: 20px
}

.most-playerd-vides .btn i {
    color: #ffc107
}

.most-playerd-vides h5 {
    font-size: 20px;
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    margin-bottom: 15px
}

.comon-mtches01 {
    border: solid 1px #ededed;
    padding: 20px;
    margin-top: 22px
}

.comon-mtches01 hr {
    margin: 0
}

.comon-mtches01 h3 {
    font-size: 30px;
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal
}

.comondubt-page {
    padding: 40px 0
}

.comon-potinm {
    border: solid 1px #e9e9e9;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, .1);
    background: #fff;
    padding: 15px;
    width: 100%;
    height: 220px;
    margin-top: 30px
}

.comon-potinm .posu-img01 {
    height: 190px
}

.comon-potinm .posu-img01 img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.comon-potinm h5 {
    font-weight: 1000;
    margin-bottom: 10px
}

.comon-potinm p {
    font-size: 16px
}

.comon-potinm .admin-t {
    margin-top: 10px;
    margin-right: 10px
}

.comon-potinm .admin-t i {
    color: #ffc107;
    margin-right: 2px
}

.comon-sec-blogs-inf-right {
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, .1);
    background: #fff;
    padding: 30px;
    display: grid;
    align-content: center;
    width: 100%;
    margin-bottom: 30px
}

.comon-sec-blogs-inf-right h5 {
    font-size: 30px;
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    margin-bottom: 15px
}

.comon-sec-blogs-inf-right .como-list figure {
    width: 90px;
    height: 80px;
    margin-bottom: 0
}

.como-list {
    margin-bottom: 18px
}

.comon-sec-blogs-inf-right .como-list .textry {
    width: 90%;
    padding-left: 20px
}

.comon-sec-blogs-inf-right .como-list .textry h5 {
    font-size: 16px;
    color: #0b132b;
    font-family: "DM Sans", sans-serif;
    margin-bottom: 8px
}

.comon-sec-blogs-inf-right .como-list figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.comon-tema {
    margin-bottom: 15px
}

.comon-tema .pluo {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden
}

.comon-tema .pluo img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.comin-uy {
    margin: 15px 0;
    border-bottom: solid 1px #ededed;
    padding-bottom: 20px
}

.comin-uy h5 {
    font-weight: 1000
}

.comin-uy p {
    font-size: 16px
}

.sub-hedr {
    font-size: 30px;
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal
}

.comon-tems {
    background: #fff;
    border: solid 1px #ededed;
    padding: 10px;
    height: 330px;
    overflow: hidden
}

.comon-tems .imf-tems {
    height: 150px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px
}

.comon-tems .imf-tems img {
    width: 50%
}

.comon-tems h5 {
    color: #0a0a0a;
    font-weight: 900;
    font-size: 18px;
    font-style: normal
}

.comon-tems h5 span {
    font-size: 14px
}

.comon-tems h5 .btn {
    background: #535cfb;
    border-radius: 50px;
    padding: 8px 30px;
    font-size: 14px
}

.comon-tems h5 .btn b {
    font-size: 20px;
    font-weight: 1000
}

.grid-tesms {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
    border: solid 1px #ebebeb !important
}

.grid-tesms .justify-content-center {
    justify-content: start !important
}

.grid-tesms .btn-tems01 {
    border: solid 1px #ebebeb !important;
    border-top: none !important;
    border-left: none !important;
    border-radius: 0;
    padding: 15px 26px
}

.grid-tesms .btn-tems01 .flagds {
    width: 59px;
    display: inline-block;
    margin-right: 25px
}

.grid-tesms .btn-tems01 h5 {
    font-weight: 900;
    color: #0a0a0a;
    text-align: left;
    font-weight: 900;
    text-align: left;
    font-size: 20px
}

.grid-tesms .btn-tems01 h5 span {
    font-weight: 500;
    color: #bfbfbf;
    font-size: 12px;
    text-transform: uppercase
}

.clender-listk {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px
}

.clender-listk a.btn {
    color: #b5b5b5;
    border: solid 1px #bbb !important;
    border-radius: 50px;
    padding: 8px 19px;
    font-weight: 500;
    margin: 10px 8px
}

.tensjh .slider-banner {
    height: 350px;
    overflow: hidden
}

.tensjh .slider-banner .container {
    position: relative;
    z-index: 2
}

.tensjh .slider-banner .txer-divu {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px
}

.tensjh .slider-banner .txer-divu h6 {
    color: #b5b5b5;
    line-height: 24px
}

.tensjh .slider-banner::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: 0;
    left: 0;
    background: linear-gradient(1.35deg, rgba(0, 0, 0, 0.8) 17.74%, rgba(0, 0, 0, 0) 89.5%);
    z-index: 1
}

.tensjh .slider-banner .fullimg {
    width: 100%;
    height: 350px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0
}

.tensjh .slider-banner .fullimg img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.txer-divu h2 {
    font-family: "Teko", sans-serif;
    font-size: 43px;
    font-weight: 600;
    color: #fff
}

.top-runs-div {
    padding: 40px 0
}

.plya-id {
    text-align: center
}

.plya-id .teams-oine {
    height: 270px;
    overflow: hidden;
    position: relative
}

.plya-id .teams-oine .btn-video {
    background: #fff;
    padding: 10px 8px;
    font-weight: 900;
    color: #0a0a0a;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 13px;
    opacity: 0;
    transition: ease-in-out .5s;
    text-transform: uppercase;
    width: 150px
}

.plya-id .teams-oine img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: top
}

.plya-id .texr-divb {
    margin-top: 15px
}

.plya-id .texr-divb h2 {
    font-weight: 1000;
    color: #0a0a0a;
    margin-top: 10px
}

.plya-id .texr-divb h5 {
    font-weight: 1000;
    color: #0a0a0a;
    font-size: 17px
}

.plya-id .texr-divb h5 span {
    font-weight: 500;
    color: #bfbdbd;
    font-size: 15px;
    margin-top: 5px
}

.plya-id:hover .btn-video {
    opacity: 1
}

.wickt-iv {
    position: relative
}

.wickt-iv .container {
    position: relative;
    z-index: 1
}

.wickt-iv::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    background: #535cfb;
    width: 100%;
    height: 280px;
    top: 0
}

.tet-div {
    position: relative;
    padding: 40px 0
}

.tet-div .container {
    position: relative
}

.tet-div::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    background: #535cfb;
    width: 100%;
    height: 280px;
    top: 0
}

.startpage {
    padding: 40px 0 50px 0
}

.startpage .comon-teams-poins {
    border: solid 2px #f5f5f5;
    background: #fff;
    padding: 15px
}

.startpage .comon-teams-poins span.noi {
    background: #535cfb;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
    font-weight: 1000
}

.startpage .comon-teams-poins h5 {
    font-weight: 800;
    text-align: center
}

.startpage .comon-teams-poins h5 span {
    font-size: 20px
}

.startpage .comon-teams-poins .linrt {
    margin-top: 8px
}

.startpage .comon-teams-poins .linrt li {
    border: solid 2px #ffc107;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
    font-weight: 1000;
    color: #ffc107
}

.startpage .comon-teams-poins .linrt .lo {
    border-color: red;
    color: red
}

.startpage .comon-teams-poins figure {
    display: table;
    width: 100px;
    margin: 15px auto
}

.startpage .comon-teams-poins figure img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.startpage .comon-teams-poins ul {
    padding: 0
}

.startpage .comon-teams-poins ul li {
    text-align: center
}

.startpage .comon-teams-poins ul li span:first-child {
    display: block;
    font-weight: 1000;
    color: #0a0a0a
}

.startpage .btn-asee {
    background: #535cfb;
    padding: 10px 30px;
    display: table;
    margin: auto;
    margin-top: 40px;
    font-weight: 1000
}

.startpage .btn-asee i {
    color: #0a0a0a
}

@media(min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1190px
    }
}

.top-new-heda {
    background: #0a0a0a;
    padding: 6px 0
}

.top-new-heda .left-oiu a {
    width: 45px;
    display: inline-block
}

.top-socli li {
    color: #fff
}

.top-socli li .btn {
    background: #3e3e3e;
    color: #fff;
    font-size: 14px;
    margin: 0 3px
}

.top-selidert {
    background: #f9f9fb;
    padding: 14px 0
}

.btn-hilihht {
    border: solid 1px rgba(0, 0, 0, .1) !important;
    border-radius: 50px;
    padding: 8px 19px;
    font-weight: 500;
    margin: 0 10px
}

.btn-hilihht i {
    margin-left: 5px
}

.match-cover01 {
    padding: 40px 0
}

.main-hed01 {
    font-size: 40px;
    font-family: "Teko", sans-serif;
    font-style: normal;
    color: #0a0a0a;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal
}

.items-cover0 {
    height: 350px
}

.items-cover0 .imghj {
    height: 200px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px
}

.items-cover0 .imghj img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.items-cover0 .details-text h5 {
    font-size: 18px;
    font-weight: 1000;
    margin-bottom: 16px;

    color: black;

}

.btn-asee {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
    font-style: normal
}

.btn-asee i {
    color: #ffc107
}

.newsli-div {
    position: relative;
    padding: 40px 0
}

.newsli-div .container {
    position: relative;
    z-index: 1
}

.newsli-div::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 280px;
    background: #232efac7
}

.newsli-div .btn-asee i {
    color: #0a0a0a
}

.comon-ret .imh-news {
    height: 187px;
    width: 100%;
    margin-bottom: 10px
}

.comon-ret .imh-news img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.comon-ret .nes-text {
    padding: 0 15px 0 8px
}

.comon-ret .nes-text h5 {
    font-size: 18px;
    font-weight: 1000;
    margin-bottom: 16px;
    margin-top: 20px
}

.comon-ret .nes-text p {
    font-size: 15px
}

.comon-ret .nes-text h6 {
    font-size: 14px
}

.big-ret .imh-news {
    height: 370px;
    width: 100%;
    margin-bottom: 15px
}

.big-ret .imh-news img {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.big-ret .nes-text h5 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: "Teko", sans-serif;
    text-transform: capitalize;
    font-style: normal;
    margin-top: 28px;
    line-height: 40px;
}

.big-ret .nes-text p {
    font-size: 17px
}

.big-ret .nes-text h6 {
    font-size: 14px;
    margin-top: 15px;
    display: block
}

@media(max-width: 1180px) {
    .navbar-light .navbar-nav .nav-link {
        font-size: 14px
    }
}

@media(max-width: 900px) {
    .bgi-text {
        display: none
    }

    .listed-bn {
        display: none !important
    }

    .mindle-heading h1 {
        font-size: 35px
    }

    .mindle-heading h1 span {
        font-size: 35px
    }

    .about-sec-home h1 {
        font-size: 35px
    }

    .about-sec-home h1 span {
        font-size: 35px
    }

    .about-1imgn {
        display: none
    }

    .about-sec-home {
        min-height: auto;
        padding-bottom: 90px !important;
        padding-top: 30px
    }

    .comon-results-div .club-items h5 {
        display: none
    }

    .bodyu-divbn h4 {
        font-size: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center
    }

    .our-small-details {
        height: auto
    }

    .right-imgplya {
        display: none !important
    }

    .our-spocerder {
        height: auto !important;
        padding-bottom: 40px
    }

    .corm-iteml figure img {
        width: 40% !important;
        margin: auto !important
    }

    .corm-iteml figure {
        padding: 20px
    }

    .slider-banner .cover .header-content {
        top: 0
    }

    .banner-part p {
        width: 60%;
        font-size: 14px
    }

    .banner-part {
        height: 400px
    }

    .banner-part .slider-banner .carousel-item {
        height: 400px
    }

    .banner-part .slider-banner .header-content {
        top: 0
    }

    .banner-part .slider-banner .header-content h2 {
        font-size: 25px;
        margin-top: 10px
    }

    .banner-part .slider-banner .header-content h2 span {
        font-size: 25px
    }

    .banner-part .slider-banner .header-content h1 {
        font-size: 30px
    }

    .banner-part .slider-banner .header-content h1 span {
        font-size: 30px
    }

    .sub-main-banner {
        height: auto !important
    }

    .btn-member {
        display: none !important
    }

    .mobile-menu-sec .active-m a {
        background: #535cfb;
        color: #fff
    }

    .mobile-menu-sec li a,
    .mobile-menu-sec li .dropdown .btn {
        text-decoration: none;
        color: #ffc107;
        padding: 10px 5px 10px 10px;
        display: block;
        background: #f7f7f7;
        margin-bottom: 3px;
        width: 100%;
        text-align: left
    }

    .btn-hilihht {
        padding: 8px 13px;
        margin: 6px
    }

    .comon-results-div .vds-resut p {
        font-size: 0
    }

    .comon-potinm {
        height: auto
    }

    .comon-potinm h5 {
        margin-top: 10px
    }

    .grid-tesms {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
}

@media(max-width: 768px) {
    .right-windre {
        width: 55%
    }

    .our-small-details .comon-divbn .right-dibvb h2 {
        font-size: 34px
    }

    .items-plays figure {
        right: 0;
        margin: auto !important;
        text-align: center;
        display: table
    }

    .items-plays .details-divn {
        left: 0;
        height: auto
    }

    .items-plays .details-divn ul li {
        display: flex;
        align-items: baseline
    }

    .rtyu h4 {
        font-size: 14px
    }

    .details-divn .rtyu {
        padding: 11px
    }

    .sponcer-logo .corm-iteml img {
        width: 80% !important;
        margin: auto
    }

    .header-content h1 {
        font-size: 26px
    }

    .header-content h1 span {
        font-size: 26px;
        display: block
    }

    .header-content h1 p {
        width: 100%
    }
}

@media(max-width: 668px) {
    .our-small-details .comon-divbn {
        text-align: center;
        margin-bottom: 20px
    }

    .our-small-details .comon-divbn figure {
        margin: auto !important;
        display: table;
        margin-bottom: 20px !important
    }

    .our-spocerder h1 {
        font-size: 45px
    }

    .our-spocerder h1 span {
        font-size: 45px
    }

    .comon-links-divb05 {
        height: 190px;
        width: 100%
    }

    .comon-links-divb05 figure {
        height: 100%
    }

    .items-plays .details-divn {
        left: 0;
        height: auto
    }

    .sponcer-logo .corm-iteml {
        height: 150px
    }

    .banner-part p {
        width: 60%
    }

    .slider-banner .item {
        height: 400px
    }

    .startpage .nav-pills .nav-link,
    .tesmas .nav-pills .nav-link,
    .top-runs-div .nav-pills .nav-link,
    .plyaers-divbn-details .nav-pills .nav-link {
        font-size: 14px;
        padding: 10px 19px
    }
}

@media(max-width: 390px) {
    .banner-part {
        height: 370px
    }

    .banner-part .slider-banner .carousel-item {
        height: 370px
    }

    .banner-part .slider-banner .header-content {
        top: 0;
        padding-left: 20px
    }

    .banner-part .slider-banner .header-content h2 {
        font-size: 25px;
        margin-top: 10px
    }

    .banner-part .slider-banner .header-content h2 span {
        font-size: 25px
    }

    .banner-part .slider-banner .header-content p {
        width: 100%
    }

    .banner-part .slider-banner .header-content h1 {
        font-size: 30px
    }

    .banner-part .slider-banner .header-content h1 span {
        font-size: 30px
    }

    .startpage .nav-pills .nav-link,
    .tesmas .nav-pills .nav-link,
    .top-runs-div .nav-pills .nav-link,
    .plyaers-divbn-details .nav-pills .nav-link {
        margin-bottom: 10px
    }

    .nav-item {
        margin: 0 10px
    }

    .grid-tesms {
        grid-template-columns: repeat(1, minmax(0, 1fr))
    }
}

@media(max-width: 375px) {
    .bgi-text {
        display: none
    }

    .mindle-heading h1 {
        font-size: 35px
    }

    .mindle-heading h1 span {
        font-size: 35px
    }

    .banner-part .baner014 {
        top: inherit !important;
        bottom: -120px;
        right: 0 !important;
        position: relative;
        width: 52% !important;
        display: inline-block;
        margin-left: auto
    }

    .header-content h1 {
        font-size: 26px
    }

    .header-content h1 span {
        font-size: 26px;
        display: block
    }

    .header-content h1 p {
        width: 100%
    }

    .header-content .btn {
        width: auto;
        font-size: 14px;
        padding: 12px 20px
    }

    .banner-part {
        height: 370px
    }

    .banner-part .slider-banner .carousel-item {
        height: 370px
    }

    .banner-part .slider-banner .header-content {
        top: 0;
        padding-left: 20px
    }

    .banner-part .slider-banner .header-content h2 {
        font-size: 25px;
        margin-top: 10px
    }

    .banner-part .slider-banner .header-content h2 span {
        font-size: 25px
    }

    .banner-part .slider-banner .header-content h1 {
        font-size: 30px
    }

    .banner-part .slider-banner .header-content h1 span {
        font-size: 30px
    }

    .slider-banner .item {
        height: 300px
    }

    .slider-banner .item>img {
        object-fit: cover;
        width: 100%;
        height: 100%
    }

    .items-plays .details-divn ul li {
        display: flex;
        flex-direction: column
    }

    .items-plays .details-divn ul li span:last-child {
        background: none !important;
        width: 100%;
        padding-left: 0
    }

    .items-plays .details-divn ul li span.rtus:after {
        border: none
    }

    .banner-part {
        height: 370px
    }

    .banner-part .slider-banner .carousel-item {
        height: 370px
    }

    .banner-part .slider-banner .header-content {
        top: 0;
        padding-left: 20px
    }

    .banner-part .slider-banner .header-content h2 {
        font-size: 25px;
        margin-top: 10px
    }

    .banner-part .slider-banner .header-content h2 span {
        font-size: 25px
    }

    .banner-part .slider-banner .header-content h1 {
        font-size: 30px
    }

    .banner-part .slider-banner .header-content h1 span {
        font-size: 30px
    }

    .btn-hilihht {
        padding: 8px 13px;
        margin: 6px
    }
}

/*# sourceMappingURL=style.min.css.map */

.truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limit to two lines */
    -webkit-box-orient: vertical;
    max-height: 3.2em;
    /* Two lines with line height */
    line-height: 1.6em;
    /* Set line height */
}

.navbar-expand-lg .navbar-collapse {
    overflow: scroll;
    margin-left: 100px;
}

.navbar-expand-lg .navbar-collapse::-webkit-scrollbar {
    width: 3px;
}

@media screen and (max-width: 991px) {
    .navbar-expand-lg .navbar-collapse {

        margin-left: 0px;
    }
}